import { BaseModal, Loader, ModalBody, ModalContent, ModalHeader } from "@bcmi-labs/art-ui/components"
import { betterLazy } from "@bcmi-labs/art-ui/utils"
import { Fragment, Suspense, useCallback, useState } from "react"

import { FlowManagerContext, type FlowPropsMapper } from "@/components/helpers/Flow"
import { useOnChange } from "@/lib/hooks/useOnChange"
import { useModalStore } from "@/store/modals"

// Lazy load all the flow steps.
const JoinSpaceModal = betterLazy(() => import("@/components/modals/flows/JoinSpace/JoinSpaceModal"))
const JoinFlowStartModal = betterLazy(() => import("@/components/modals/flows/JoinSpace/JoinFlowStartModal"))
const JoinFlowFinishModal = betterLazy(() => import("@/components/modals/flows/JoinSpace/JoinFlowFinishModal"))
const JoinErrorModal = betterLazy(() => import("@/components/modals/flows/JoinSpace/JoinErrorModal"))

export const joinSpaceflowSteps = {
  join: JoinSpaceModal,
  start: JoinFlowStartModal,
  end: JoinFlowFinishModal,
  error: JoinErrorModal
} as const

// Declare context methods for each flow step.
const propsMap: FlowPropsMapper<typeof joinSpaceflowSteps> = {
  join: {
    next: () => useModalStore.getState().setJoinSpaceFlowStep("start")
  },
  start: { next: () => useModalStore.getState().setJoinSpaceFlowStep("end") },
  end: {
    next: () => useModalStore.getState().setJoinSpaceFlowStep(undefined)
  },
  error: {
    next: () => useModalStore.getState().setJoinSpaceFlowStep(undefined)
  }
}

/**
 * JoinSpaceFlow manager
 */
export function JoinSpaceFlow() {
  const joinSpaceFlow = useModalStore(s => s.joinSpaceFlow)
  const setJoinSpaceFlowStep = useModalStore(s => s.setJoinSpaceFlowStep)

  const Comp = joinSpaceFlow.step ? joinSpaceflowSteps[joinSpaceFlow.step] : Fragment

  const [prefetched, setPrefetched] = useState(false)
  const [open, setOpen] = useState(!!joinSpaceFlow.step)

  useOnChange(!!joinSpaceFlow.step, (_, curr) => {
    setOpen(curr)
    // If we moved to true and the agent is not created, create it.
    if (curr) {
      if (!prefetched)
        Promise.all(Object.values(joinSpaceflowSteps).map(step => step.prefetch())).then(() => setPrefetched(true))
    }
  })

  const closeFlow = useCallback(() => {
    setJoinSpaceFlowStep(undefined)
  }, [setJoinSpaceFlowStep])

  return (
    <FlowManagerContext ctx={{ close: () => closeFlow(), reset: () => closeFlow() }}>
      <BaseModal open={open} onOpenChange={setOpen} contentProps={{ onCloseAutoFocus: closeFlow }}>
        <Suspense
          fallback={
            <ModalContent style={{ minHeight: "300px" }}>
              <ModalHeader title="Loading flow..." />
              <ModalBody>
                <div className="flex-center">
                  <Loader />
                </div>
              </ModalBody>
            </ModalContent>
          }>
          {joinSpaceFlow.step ? <Comp {...propsMap[joinSpaceFlow.step]} /> : null}
        </Suspense>
      </BaseModal>
    </FlowManagerContext>
  )
}
