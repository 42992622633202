import createClient from "openapi-react-query"

import { createApiClient } from "@/api/utils"
import type { paths as otaPaths } from "@/types/api/ota/schema"

// Vanilla API client
export const $otaApiClient = createApiClient<otaPaths>({ baseUrl: `${import.meta.env.VITE_API_URL}/ota` })

// React-Query client
export const $rqOtaApiClient = createClient($otaApiClient)
