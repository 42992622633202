import { GenericError } from "@bcmi-labs/art-auth"
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"
import { deploymentPath } from "@/config"
import { MY_SPACE } from "@/constants/spaces"

export const Route = createFileRoute("/_authlayout")({
  beforeLoad: async ({ context: { authClient }, location }) => {
    const customization = authClient.getCustomization()
    // If there's no customization on a custom domain, or the URL is disabled, redirect to auth-failed route
    if (deploymentPath.customKey && (!customization || !customization.url_enabled)) {
      redirect({
        to: "/auth-failed",
        throw: true
      })
    }

    // User is not logged in and there are no auth params in the URL: redirect to login
    if (!(await authClient.isAuthenticated())) {
      const params = new URLSearchParams(window.location.search)
      params.set("pathname", window.location.pathname)

      // Ask for a token; this way we can check if the user has some sort of authentication error.
      // If they do, we throw an error. If they're just not logged in, we redirect them to the login page.

      try {
        await authClient.getTokenSilently()
      } catch (error) {
        if (error instanceof GenericError) {
          if (error.error === "login_required") {
            await authClient.loginWithRedirect({ appState: params.toString() })
            return
          }
          throw error
        }
      }

      return
    }

    // User is logged, but hasn't accepted TOS of custom domain: go to TOS page
    if (
      authClient.getCustomization() &&
      !(await authClient.checkTosAcceptance()) &&
      location.pathname !== "/tos-acceptance"
    ) {
      redirect({
        to: "/tos-acceptance",
        throw: true
      })
    }
  },
  loader: async ({ context: { queryClient, spaceStorage } }) => {
    const spaces = await queryClient.ensureQueryData(query.space.list)
    const space = spaces.find(sp => sp.id === spaceStorage.getSpace()) || MY_SPACE

    // Ensure some data is preloaded, so we don't have to wait for it when the user navigates to a page.
    queryClient.ensureQueryData(query.space.subscriptions(space.id))
    queryClient.ensureQueryData(query.space.subscriptions(MY_SPACE.id))

    queryClient.ensureQueryData(query.space.kits(MY_SPACE.id))

    // Only fetch space kits if the space is not the pro space
    if (space.space !== "pro") {
      queryClient.ensureQueryData(query.space.kits(space.id))
    }
  },
  component: Outlet
})
