/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './pages/__root'
import { Route as ChromeAppImport } from './pages/chrome-app'
import { Route as NoAuthImport } from './pages/_no-auth'
import { Route as AuthlayoutImport } from './pages/_authlayout'
import { Route as NoAuthMinorsImport } from './pages/_no-auth/minors'
import { Route as NoAuthAuthFailedIndexImport } from './pages/_no-auth/auth-failed/index'
import { Route as AuthlayoutSidebarIndexImport } from './pages/_authlayout/_sidebar/index'
import { Route as AuthlayoutSidebarTriggersIndexImport } from './pages/_authlayout/_sidebar/triggers/index'
import { Route as AuthlayoutSidebarTrashIndexImport } from './pages/_authlayout/_sidebar/trash/index'
import { Route as AuthlayoutSidebarThingsIndexImport } from './pages/_authlayout/_sidebar/things/index'
import { Route as AuthlayoutSidebarTemplatesIndexImport } from './pages/_authlayout/_sidebar/templates/index'
import { Route as AuthlayoutSidebarSketchesIndexImport } from './pages/_authlayout/_sidebar/sketches/index'
import { Route as AuthlayoutSidebarSettingsIndexImport } from './pages/_authlayout/_sidebar/settings/index'
import { Route as AuthlayoutSidebarRolesPermissionsIndexImport } from './pages/_authlayout/_sidebar/roles-permissions/index'
import { Route as AuthlayoutSidebarResourcesIndexImport } from './pages/_authlayout/_sidebar/resources/index'
import { Route as AuthlayoutSidebarPlanUsageIndexImport } from './pages/_authlayout/_sidebar/plan-usage/index'
import { Route as AuthlayoutSidebarMembersIndexImport } from './pages/_authlayout/_sidebar/members/index'
import { Route as AuthlayoutSidebarIntegrationsIndexImport } from './pages/_authlayout/_sidebar/integrations/index'
import { Route as AuthlayoutSidebarDevicesIndexImport } from './pages/_authlayout/_sidebar/devices/index'
import { Route as AuthlayoutSidebarDashboardsIndexImport } from './pages/_authlayout/_sidebar/dashboards/index'
import { Route as AuthlayoutSidebarCoursesIndexImport } from './pages/_authlayout/_sidebar/courses/index'
import { Route as AuthlayoutSidebarBrandingIndexImport } from './pages/_authlayout/_sidebar/branding/index'
import { Route as AuthlayoutSidebarApiKeysIndexImport } from './pages/_authlayout/_sidebar/api-keys/index'
import { Route as AuthlayoutSidebarAllitemsIndexImport } from './pages/_authlayout/_sidebar/allitems/index'
import { Route as AuthlayoutNoneTosAcceptanceIndexImport } from './pages/_authlayout/_none/tos-acceptance/index'
import { Route as AuthlayoutNoneSpaceIndexImport } from './pages/_authlayout/_none/space/index'
import { Route as AuthlayoutNoneRegistrationIndexImport } from './pages/_authlayout/_none/registration/index'
import { Route as AuthlayoutHeaderDevicesIdImport } from './pages/_authlayout/_header/devices/$id'
import { Route as AuthlayoutHeaderTemplatesNewIndexImport } from './pages/_authlayout/_header/templates/new/index'
import { Route as AuthlayoutHeaderTemplatesIdIndexImport } from './pages/_authlayout/_header/templates/$id/index'
import { Route as AuthlayoutHeaderTemplatesIdEditIndexImport } from './pages/_authlayout/_header/templates/$id/edit/index'

// Create Virtual Routes

const AuthlayoutSidebarLazyImport = createFileRoute('/_authlayout/_sidebar')()
const AuthlayoutNoneLazyImport = createFileRoute('/_authlayout/_none')()
const AuthlayoutHeaderLazyImport = createFileRoute('/_authlayout/_header')()
const AuthlayoutNoneSpaceEducationLazyImport = createFileRoute(
  '/_authlayout/_none/space/education',
)()

// Create/Update Routes

const ChromeAppRoute = ChromeAppImport.update({
  id: '/chrome-app',
  path: '/chrome-app',
  getParentRoute: () => rootRoute,
} as any)

const NoAuthRoute = NoAuthImport.update({
  id: '/_no-auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthlayoutRoute = AuthlayoutImport.update({
  id: '/_authlayout',
  getParentRoute: () => rootRoute,
} as any)

const AuthlayoutSidebarLazyRoute = AuthlayoutSidebarLazyImport.update({
  id: '/_sidebar',
  getParentRoute: () => AuthlayoutRoute,
} as any).lazy(() =>
  import('./pages/_authlayout/_sidebar.lazy').then((d) => d.Route),
)

const AuthlayoutNoneLazyRoute = AuthlayoutNoneLazyImport.update({
  id: '/_none',
  getParentRoute: () => AuthlayoutRoute,
} as any).lazy(() =>
  import('./pages/_authlayout/_none.lazy').then((d) => d.Route),
)

const AuthlayoutHeaderLazyRoute = AuthlayoutHeaderLazyImport.update({
  id: '/_header',
  getParentRoute: () => AuthlayoutRoute,
} as any).lazy(() =>
  import('./pages/_authlayout/_header.lazy').then((d) => d.Route),
)

const NoAuthMinorsRoute = NoAuthMinorsImport.update({
  id: '/minors',
  path: '/minors',
  getParentRoute: () => NoAuthRoute,
} as any)

const NoAuthAuthFailedIndexRoute = NoAuthAuthFailedIndexImport.update({
  id: '/auth-failed/',
  path: '/auth-failed/',
  getParentRoute: () => NoAuthRoute,
} as any).lazy(() =>
  import('./pages/_no-auth/auth-failed/index.lazy').then((d) => d.Route),
)

const AuthlayoutSidebarIndexRoute = AuthlayoutSidebarIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthlayoutSidebarLazyRoute,
} as any).lazy(() =>
  import('./pages/_authlayout/_sidebar/index.lazy').then((d) => d.Route),
)

const AuthlayoutSidebarTriggersIndexRoute =
  AuthlayoutSidebarTriggersIndexImport.update({
    id: '/triggers/',
    path: '/triggers/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/triggers/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarTrashIndexRoute =
  AuthlayoutSidebarTrashIndexImport.update({
    id: '/trash/',
    path: '/trash/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/trash/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarThingsIndexRoute =
  AuthlayoutSidebarThingsIndexImport.update({
    id: '/things/',
    path: '/things/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/things/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarTemplatesIndexRoute =
  AuthlayoutSidebarTemplatesIndexImport.update({
    id: '/templates/',
    path: '/templates/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/templates/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarSketchesIndexRoute =
  AuthlayoutSidebarSketchesIndexImport.update({
    id: '/sketches/',
    path: '/sketches/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/sketches/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarSettingsIndexRoute =
  AuthlayoutSidebarSettingsIndexImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarRolesPermissionsIndexRoute =
  AuthlayoutSidebarRolesPermissionsIndexImport.update({
    id: '/roles-permissions/',
    path: '/roles-permissions/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/roles-permissions/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarResourcesIndexRoute =
  AuthlayoutSidebarResourcesIndexImport.update({
    id: '/resources/',
    path: '/resources/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/resources/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarPlanUsageIndexRoute =
  AuthlayoutSidebarPlanUsageIndexImport.update({
    id: '/plan-usage/',
    path: '/plan-usage/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/plan-usage/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarMembersIndexRoute =
  AuthlayoutSidebarMembersIndexImport.update({
    id: '/members/',
    path: '/members/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/members/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarIntegrationsIndexRoute =
  AuthlayoutSidebarIntegrationsIndexImport.update({
    id: '/integrations/',
    path: '/integrations/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/integrations/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarDevicesIndexRoute =
  AuthlayoutSidebarDevicesIndexImport.update({
    id: '/devices/',
    path: '/devices/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/devices/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarDashboardsIndexRoute =
  AuthlayoutSidebarDashboardsIndexImport.update({
    id: '/dashboards/',
    path: '/dashboards/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/dashboards/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarCoursesIndexRoute =
  AuthlayoutSidebarCoursesIndexImport.update({
    id: '/courses/',
    path: '/courses/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/courses/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarBrandingIndexRoute =
  AuthlayoutSidebarBrandingIndexImport.update({
    id: '/branding/',
    path: '/branding/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/branding/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarApiKeysIndexRoute =
  AuthlayoutSidebarApiKeysIndexImport.update({
    id: '/api-keys/',
    path: '/api-keys/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/api-keys/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutSidebarAllitemsIndexRoute =
  AuthlayoutSidebarAllitemsIndexImport.update({
    id: '/allitems/',
    path: '/allitems/',
    getParentRoute: () => AuthlayoutSidebarLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_sidebar/allitems/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutNoneTosAcceptanceIndexRoute =
  AuthlayoutNoneTosAcceptanceIndexImport.update({
    id: '/tos-acceptance/',
    path: '/tos-acceptance/',
    getParentRoute: () => AuthlayoutNoneLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_none/tos-acceptance/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutNoneSpaceIndexRoute = AuthlayoutNoneSpaceIndexImport.update({
  id: '/space/',
  path: '/space/',
  getParentRoute: () => AuthlayoutNoneLazyRoute,
} as any).lazy(() =>
  import('./pages/_authlayout/_none/space/index.lazy').then((d) => d.Route),
)

const AuthlayoutNoneRegistrationIndexRoute =
  AuthlayoutNoneRegistrationIndexImport.update({
    id: '/registration/',
    path: '/registration/',
    getParentRoute: () => AuthlayoutNoneLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_none/registration/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutNoneSpaceEducationLazyRoute =
  AuthlayoutNoneSpaceEducationLazyImport.update({
    id: '/space/education',
    path: '/space/education',
    getParentRoute: () => AuthlayoutNoneLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_none/space/education.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutHeaderDevicesIdRoute = AuthlayoutHeaderDevicesIdImport.update({
  id: '/devices/$id',
  path: '/devices/$id',
  getParentRoute: () => AuthlayoutHeaderLazyRoute,
} as any).lazy(() =>
  import('./pages/_authlayout/_header/devices/$id.lazy').then((d) => d.Route),
)

const AuthlayoutHeaderTemplatesNewIndexRoute =
  AuthlayoutHeaderTemplatesNewIndexImport.update({
    id: '/templates/new/',
    path: '/templates/new/',
    getParentRoute: () => AuthlayoutHeaderLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_header/templates/new/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutHeaderTemplatesIdIndexRoute =
  AuthlayoutHeaderTemplatesIdIndexImport.update({
    id: '/templates/$id/',
    path: '/templates/$id/',
    getParentRoute: () => AuthlayoutHeaderLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_header/templates/$id/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthlayoutHeaderTemplatesIdEditIndexRoute =
  AuthlayoutHeaderTemplatesIdEditIndexImport.update({
    id: '/templates/$id/edit/',
    path: '/templates/$id/edit/',
    getParentRoute: () => AuthlayoutHeaderLazyRoute,
  } as any).lazy(() =>
    import('./pages/_authlayout/_header/templates/$id/edit/index.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authlayout': {
      id: '/_authlayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthlayoutImport
      parentRoute: typeof rootRoute
    }
    '/_no-auth': {
      id: '/_no-auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof NoAuthImport
      parentRoute: typeof rootRoute
    }
    '/chrome-app': {
      id: '/chrome-app'
      path: '/chrome-app'
      fullPath: '/chrome-app'
      preLoaderRoute: typeof ChromeAppImport
      parentRoute: typeof rootRoute
    }
    '/_no-auth/minors': {
      id: '/_no-auth/minors'
      path: '/minors'
      fullPath: '/minors'
      preLoaderRoute: typeof NoAuthMinorsImport
      parentRoute: typeof NoAuthImport
    }
    '/_authlayout/_header': {
      id: '/_authlayout/_header'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthlayoutHeaderLazyImport
      parentRoute: typeof AuthlayoutImport
    }
    '/_authlayout/_none': {
      id: '/_authlayout/_none'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthlayoutNoneLazyImport
      parentRoute: typeof AuthlayoutImport
    }
    '/_authlayout/_sidebar': {
      id: '/_authlayout/_sidebar'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthlayoutSidebarLazyImport
      parentRoute: typeof AuthlayoutImport
    }
    '/_authlayout/_sidebar/': {
      id: '/_authlayout/_sidebar/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthlayoutSidebarIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_no-auth/auth-failed/': {
      id: '/_no-auth/auth-failed/'
      path: '/auth-failed'
      fullPath: '/auth-failed'
      preLoaderRoute: typeof NoAuthAuthFailedIndexImport
      parentRoute: typeof NoAuthImport
    }
    '/_authlayout/_header/devices/$id': {
      id: '/_authlayout/_header/devices/$id'
      path: '/devices/$id'
      fullPath: '/devices/$id'
      preLoaderRoute: typeof AuthlayoutHeaderDevicesIdImport
      parentRoute: typeof AuthlayoutHeaderLazyImport
    }
    '/_authlayout/_none/space/education': {
      id: '/_authlayout/_none/space/education'
      path: '/space/education'
      fullPath: '/space/education'
      preLoaderRoute: typeof AuthlayoutNoneSpaceEducationLazyImport
      parentRoute: typeof AuthlayoutNoneLazyImport
    }
    '/_authlayout/_none/registration/': {
      id: '/_authlayout/_none/registration/'
      path: '/registration'
      fullPath: '/registration'
      preLoaderRoute: typeof AuthlayoutNoneRegistrationIndexImport
      parentRoute: typeof AuthlayoutNoneLazyImport
    }
    '/_authlayout/_none/space/': {
      id: '/_authlayout/_none/space/'
      path: '/space'
      fullPath: '/space'
      preLoaderRoute: typeof AuthlayoutNoneSpaceIndexImport
      parentRoute: typeof AuthlayoutNoneLazyImport
    }
    '/_authlayout/_none/tos-acceptance/': {
      id: '/_authlayout/_none/tos-acceptance/'
      path: '/tos-acceptance'
      fullPath: '/tos-acceptance'
      preLoaderRoute: typeof AuthlayoutNoneTosAcceptanceIndexImport
      parentRoute: typeof AuthlayoutNoneLazyImport
    }
    '/_authlayout/_sidebar/allitems/': {
      id: '/_authlayout/_sidebar/allitems/'
      path: '/allitems'
      fullPath: '/allitems'
      preLoaderRoute: typeof AuthlayoutSidebarAllitemsIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/api-keys/': {
      id: '/_authlayout/_sidebar/api-keys/'
      path: '/api-keys'
      fullPath: '/api-keys'
      preLoaderRoute: typeof AuthlayoutSidebarApiKeysIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/branding/': {
      id: '/_authlayout/_sidebar/branding/'
      path: '/branding'
      fullPath: '/branding'
      preLoaderRoute: typeof AuthlayoutSidebarBrandingIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/courses/': {
      id: '/_authlayout/_sidebar/courses/'
      path: '/courses'
      fullPath: '/courses'
      preLoaderRoute: typeof AuthlayoutSidebarCoursesIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/dashboards/': {
      id: '/_authlayout/_sidebar/dashboards/'
      path: '/dashboards'
      fullPath: '/dashboards'
      preLoaderRoute: typeof AuthlayoutSidebarDashboardsIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/devices/': {
      id: '/_authlayout/_sidebar/devices/'
      path: '/devices'
      fullPath: '/devices'
      preLoaderRoute: typeof AuthlayoutSidebarDevicesIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/integrations/': {
      id: '/_authlayout/_sidebar/integrations/'
      path: '/integrations'
      fullPath: '/integrations'
      preLoaderRoute: typeof AuthlayoutSidebarIntegrationsIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/members/': {
      id: '/_authlayout/_sidebar/members/'
      path: '/members'
      fullPath: '/members'
      preLoaderRoute: typeof AuthlayoutSidebarMembersIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/plan-usage/': {
      id: '/_authlayout/_sidebar/plan-usage/'
      path: '/plan-usage'
      fullPath: '/plan-usage'
      preLoaderRoute: typeof AuthlayoutSidebarPlanUsageIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/resources/': {
      id: '/_authlayout/_sidebar/resources/'
      path: '/resources'
      fullPath: '/resources'
      preLoaderRoute: typeof AuthlayoutSidebarResourcesIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/roles-permissions/': {
      id: '/_authlayout/_sidebar/roles-permissions/'
      path: '/roles-permissions'
      fullPath: '/roles-permissions'
      preLoaderRoute: typeof AuthlayoutSidebarRolesPermissionsIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/settings/': {
      id: '/_authlayout/_sidebar/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthlayoutSidebarSettingsIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/sketches/': {
      id: '/_authlayout/_sidebar/sketches/'
      path: '/sketches'
      fullPath: '/sketches'
      preLoaderRoute: typeof AuthlayoutSidebarSketchesIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/templates/': {
      id: '/_authlayout/_sidebar/templates/'
      path: '/templates'
      fullPath: '/templates'
      preLoaderRoute: typeof AuthlayoutSidebarTemplatesIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/things/': {
      id: '/_authlayout/_sidebar/things/'
      path: '/things'
      fullPath: '/things'
      preLoaderRoute: typeof AuthlayoutSidebarThingsIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/trash/': {
      id: '/_authlayout/_sidebar/trash/'
      path: '/trash'
      fullPath: '/trash'
      preLoaderRoute: typeof AuthlayoutSidebarTrashIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_sidebar/triggers/': {
      id: '/_authlayout/_sidebar/triggers/'
      path: '/triggers'
      fullPath: '/triggers'
      preLoaderRoute: typeof AuthlayoutSidebarTriggersIndexImport
      parentRoute: typeof AuthlayoutSidebarLazyImport
    }
    '/_authlayout/_header/templates/$id/': {
      id: '/_authlayout/_header/templates/$id/'
      path: '/templates/$id'
      fullPath: '/templates/$id'
      preLoaderRoute: typeof AuthlayoutHeaderTemplatesIdIndexImport
      parentRoute: typeof AuthlayoutHeaderLazyImport
    }
    '/_authlayout/_header/templates/new/': {
      id: '/_authlayout/_header/templates/new/'
      path: '/templates/new'
      fullPath: '/templates/new'
      preLoaderRoute: typeof AuthlayoutHeaderTemplatesNewIndexImport
      parentRoute: typeof AuthlayoutHeaderLazyImport
    }
    '/_authlayout/_header/templates/$id/edit/': {
      id: '/_authlayout/_header/templates/$id/edit/'
      path: '/templates/$id/edit'
      fullPath: '/templates/$id/edit'
      preLoaderRoute: typeof AuthlayoutHeaderTemplatesIdEditIndexImport
      parentRoute: typeof AuthlayoutHeaderLazyImport
    }
  }
}

// Create and export the route tree

interface AuthlayoutHeaderLazyRouteChildren {
  AuthlayoutHeaderDevicesIdRoute: typeof AuthlayoutHeaderDevicesIdRoute
  AuthlayoutHeaderTemplatesIdIndexRoute: typeof AuthlayoutHeaderTemplatesIdIndexRoute
  AuthlayoutHeaderTemplatesNewIndexRoute: typeof AuthlayoutHeaderTemplatesNewIndexRoute
  AuthlayoutHeaderTemplatesIdEditIndexRoute: typeof AuthlayoutHeaderTemplatesIdEditIndexRoute
}

const AuthlayoutHeaderLazyRouteChildren: AuthlayoutHeaderLazyRouteChildren = {
  AuthlayoutHeaderDevicesIdRoute: AuthlayoutHeaderDevicesIdRoute,
  AuthlayoutHeaderTemplatesIdIndexRoute: AuthlayoutHeaderTemplatesIdIndexRoute,
  AuthlayoutHeaderTemplatesNewIndexRoute:
    AuthlayoutHeaderTemplatesNewIndexRoute,
  AuthlayoutHeaderTemplatesIdEditIndexRoute:
    AuthlayoutHeaderTemplatesIdEditIndexRoute,
}

const AuthlayoutHeaderLazyRouteWithChildren =
  AuthlayoutHeaderLazyRoute._addFileChildren(AuthlayoutHeaderLazyRouteChildren)

interface AuthlayoutNoneLazyRouteChildren {
  AuthlayoutNoneSpaceEducationLazyRoute: typeof AuthlayoutNoneSpaceEducationLazyRoute
  AuthlayoutNoneRegistrationIndexRoute: typeof AuthlayoutNoneRegistrationIndexRoute
  AuthlayoutNoneSpaceIndexRoute: typeof AuthlayoutNoneSpaceIndexRoute
  AuthlayoutNoneTosAcceptanceIndexRoute: typeof AuthlayoutNoneTosAcceptanceIndexRoute
}

const AuthlayoutNoneLazyRouteChildren: AuthlayoutNoneLazyRouteChildren = {
  AuthlayoutNoneSpaceEducationLazyRoute: AuthlayoutNoneSpaceEducationLazyRoute,
  AuthlayoutNoneRegistrationIndexRoute: AuthlayoutNoneRegistrationIndexRoute,
  AuthlayoutNoneSpaceIndexRoute: AuthlayoutNoneSpaceIndexRoute,
  AuthlayoutNoneTosAcceptanceIndexRoute: AuthlayoutNoneTosAcceptanceIndexRoute,
}

const AuthlayoutNoneLazyRouteWithChildren =
  AuthlayoutNoneLazyRoute._addFileChildren(AuthlayoutNoneLazyRouteChildren)

interface AuthlayoutSidebarLazyRouteChildren {
  AuthlayoutSidebarIndexRoute: typeof AuthlayoutSidebarIndexRoute
  AuthlayoutSidebarAllitemsIndexRoute: typeof AuthlayoutSidebarAllitemsIndexRoute
  AuthlayoutSidebarApiKeysIndexRoute: typeof AuthlayoutSidebarApiKeysIndexRoute
  AuthlayoutSidebarBrandingIndexRoute: typeof AuthlayoutSidebarBrandingIndexRoute
  AuthlayoutSidebarCoursesIndexRoute: typeof AuthlayoutSidebarCoursesIndexRoute
  AuthlayoutSidebarDashboardsIndexRoute: typeof AuthlayoutSidebarDashboardsIndexRoute
  AuthlayoutSidebarDevicesIndexRoute: typeof AuthlayoutSidebarDevicesIndexRoute
  AuthlayoutSidebarIntegrationsIndexRoute: typeof AuthlayoutSidebarIntegrationsIndexRoute
  AuthlayoutSidebarMembersIndexRoute: typeof AuthlayoutSidebarMembersIndexRoute
  AuthlayoutSidebarPlanUsageIndexRoute: typeof AuthlayoutSidebarPlanUsageIndexRoute
  AuthlayoutSidebarResourcesIndexRoute: typeof AuthlayoutSidebarResourcesIndexRoute
  AuthlayoutSidebarRolesPermissionsIndexRoute: typeof AuthlayoutSidebarRolesPermissionsIndexRoute
  AuthlayoutSidebarSettingsIndexRoute: typeof AuthlayoutSidebarSettingsIndexRoute
  AuthlayoutSidebarSketchesIndexRoute: typeof AuthlayoutSidebarSketchesIndexRoute
  AuthlayoutSidebarTemplatesIndexRoute: typeof AuthlayoutSidebarTemplatesIndexRoute
  AuthlayoutSidebarThingsIndexRoute: typeof AuthlayoutSidebarThingsIndexRoute
  AuthlayoutSidebarTrashIndexRoute: typeof AuthlayoutSidebarTrashIndexRoute
  AuthlayoutSidebarTriggersIndexRoute: typeof AuthlayoutSidebarTriggersIndexRoute
}

const AuthlayoutSidebarLazyRouteChildren: AuthlayoutSidebarLazyRouteChildren = {
  AuthlayoutSidebarIndexRoute: AuthlayoutSidebarIndexRoute,
  AuthlayoutSidebarAllitemsIndexRoute: AuthlayoutSidebarAllitemsIndexRoute,
  AuthlayoutSidebarApiKeysIndexRoute: AuthlayoutSidebarApiKeysIndexRoute,
  AuthlayoutSidebarBrandingIndexRoute: AuthlayoutSidebarBrandingIndexRoute,
  AuthlayoutSidebarCoursesIndexRoute: AuthlayoutSidebarCoursesIndexRoute,
  AuthlayoutSidebarDashboardsIndexRoute: AuthlayoutSidebarDashboardsIndexRoute,
  AuthlayoutSidebarDevicesIndexRoute: AuthlayoutSidebarDevicesIndexRoute,
  AuthlayoutSidebarIntegrationsIndexRoute:
    AuthlayoutSidebarIntegrationsIndexRoute,
  AuthlayoutSidebarMembersIndexRoute: AuthlayoutSidebarMembersIndexRoute,
  AuthlayoutSidebarPlanUsageIndexRoute: AuthlayoutSidebarPlanUsageIndexRoute,
  AuthlayoutSidebarResourcesIndexRoute: AuthlayoutSidebarResourcesIndexRoute,
  AuthlayoutSidebarRolesPermissionsIndexRoute:
    AuthlayoutSidebarRolesPermissionsIndexRoute,
  AuthlayoutSidebarSettingsIndexRoute: AuthlayoutSidebarSettingsIndexRoute,
  AuthlayoutSidebarSketchesIndexRoute: AuthlayoutSidebarSketchesIndexRoute,
  AuthlayoutSidebarTemplatesIndexRoute: AuthlayoutSidebarTemplatesIndexRoute,
  AuthlayoutSidebarThingsIndexRoute: AuthlayoutSidebarThingsIndexRoute,
  AuthlayoutSidebarTrashIndexRoute: AuthlayoutSidebarTrashIndexRoute,
  AuthlayoutSidebarTriggersIndexRoute: AuthlayoutSidebarTriggersIndexRoute,
}

const AuthlayoutSidebarLazyRouteWithChildren =
  AuthlayoutSidebarLazyRoute._addFileChildren(
    AuthlayoutSidebarLazyRouteChildren,
  )

interface AuthlayoutRouteChildren {
  AuthlayoutHeaderLazyRoute: typeof AuthlayoutHeaderLazyRouteWithChildren
  AuthlayoutNoneLazyRoute: typeof AuthlayoutNoneLazyRouteWithChildren
  AuthlayoutSidebarLazyRoute: typeof AuthlayoutSidebarLazyRouteWithChildren
}

const AuthlayoutRouteChildren: AuthlayoutRouteChildren = {
  AuthlayoutHeaderLazyRoute: AuthlayoutHeaderLazyRouteWithChildren,
  AuthlayoutNoneLazyRoute: AuthlayoutNoneLazyRouteWithChildren,
  AuthlayoutSidebarLazyRoute: AuthlayoutSidebarLazyRouteWithChildren,
}

const AuthlayoutRouteWithChildren = AuthlayoutRoute._addFileChildren(
  AuthlayoutRouteChildren,
)

interface NoAuthRouteChildren {
  NoAuthMinorsRoute: typeof NoAuthMinorsRoute
  NoAuthAuthFailedIndexRoute: typeof NoAuthAuthFailedIndexRoute
}

const NoAuthRouteChildren: NoAuthRouteChildren = {
  NoAuthMinorsRoute: NoAuthMinorsRoute,
  NoAuthAuthFailedIndexRoute: NoAuthAuthFailedIndexRoute,
}

const NoAuthRouteWithChildren =
  NoAuthRoute._addFileChildren(NoAuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthlayoutSidebarLazyRouteWithChildren
  '/chrome-app': typeof ChromeAppRoute
  '/minors': typeof NoAuthMinorsRoute
  '/': typeof AuthlayoutSidebarIndexRoute
  '/auth-failed': typeof NoAuthAuthFailedIndexRoute
  '/devices/$id': typeof AuthlayoutHeaderDevicesIdRoute
  '/space/education': typeof AuthlayoutNoneSpaceEducationLazyRoute
  '/registration': typeof AuthlayoutNoneRegistrationIndexRoute
  '/space': typeof AuthlayoutNoneSpaceIndexRoute
  '/tos-acceptance': typeof AuthlayoutNoneTosAcceptanceIndexRoute
  '/allitems': typeof AuthlayoutSidebarAllitemsIndexRoute
  '/api-keys': typeof AuthlayoutSidebarApiKeysIndexRoute
  '/branding': typeof AuthlayoutSidebarBrandingIndexRoute
  '/courses': typeof AuthlayoutSidebarCoursesIndexRoute
  '/dashboards': typeof AuthlayoutSidebarDashboardsIndexRoute
  '/devices': typeof AuthlayoutSidebarDevicesIndexRoute
  '/integrations': typeof AuthlayoutSidebarIntegrationsIndexRoute
  '/members': typeof AuthlayoutSidebarMembersIndexRoute
  '/plan-usage': typeof AuthlayoutSidebarPlanUsageIndexRoute
  '/resources': typeof AuthlayoutSidebarResourcesIndexRoute
  '/roles-permissions': typeof AuthlayoutSidebarRolesPermissionsIndexRoute
  '/settings': typeof AuthlayoutSidebarSettingsIndexRoute
  '/sketches': typeof AuthlayoutSidebarSketchesIndexRoute
  '/templates': typeof AuthlayoutSidebarTemplatesIndexRoute
  '/things': typeof AuthlayoutSidebarThingsIndexRoute
  '/trash': typeof AuthlayoutSidebarTrashIndexRoute
  '/triggers': typeof AuthlayoutSidebarTriggersIndexRoute
  '/templates/$id': typeof AuthlayoutHeaderTemplatesIdIndexRoute
  '/templates/new': typeof AuthlayoutHeaderTemplatesNewIndexRoute
  '/templates/$id/edit': typeof AuthlayoutHeaderTemplatesIdEditIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthlayoutNoneLazyRouteWithChildren
  '/chrome-app': typeof ChromeAppRoute
  '/minors': typeof NoAuthMinorsRoute
  '/': typeof AuthlayoutSidebarIndexRoute
  '/auth-failed': typeof NoAuthAuthFailedIndexRoute
  '/devices/$id': typeof AuthlayoutHeaderDevicesIdRoute
  '/space/education': typeof AuthlayoutNoneSpaceEducationLazyRoute
  '/registration': typeof AuthlayoutNoneRegistrationIndexRoute
  '/space': typeof AuthlayoutNoneSpaceIndexRoute
  '/tos-acceptance': typeof AuthlayoutNoneTosAcceptanceIndexRoute
  '/allitems': typeof AuthlayoutSidebarAllitemsIndexRoute
  '/api-keys': typeof AuthlayoutSidebarApiKeysIndexRoute
  '/branding': typeof AuthlayoutSidebarBrandingIndexRoute
  '/courses': typeof AuthlayoutSidebarCoursesIndexRoute
  '/dashboards': typeof AuthlayoutSidebarDashboardsIndexRoute
  '/devices': typeof AuthlayoutSidebarDevicesIndexRoute
  '/integrations': typeof AuthlayoutSidebarIntegrationsIndexRoute
  '/members': typeof AuthlayoutSidebarMembersIndexRoute
  '/plan-usage': typeof AuthlayoutSidebarPlanUsageIndexRoute
  '/resources': typeof AuthlayoutSidebarResourcesIndexRoute
  '/roles-permissions': typeof AuthlayoutSidebarRolesPermissionsIndexRoute
  '/settings': typeof AuthlayoutSidebarSettingsIndexRoute
  '/sketches': typeof AuthlayoutSidebarSketchesIndexRoute
  '/templates': typeof AuthlayoutSidebarTemplatesIndexRoute
  '/things': typeof AuthlayoutSidebarThingsIndexRoute
  '/trash': typeof AuthlayoutSidebarTrashIndexRoute
  '/triggers': typeof AuthlayoutSidebarTriggersIndexRoute
  '/templates/$id': typeof AuthlayoutHeaderTemplatesIdIndexRoute
  '/templates/new': typeof AuthlayoutHeaderTemplatesNewIndexRoute
  '/templates/$id/edit': typeof AuthlayoutHeaderTemplatesIdEditIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authlayout': typeof AuthlayoutRouteWithChildren
  '/_no-auth': typeof NoAuthRouteWithChildren
  '/chrome-app': typeof ChromeAppRoute
  '/_no-auth/minors': typeof NoAuthMinorsRoute
  '/_authlayout/_header': typeof AuthlayoutHeaderLazyRouteWithChildren
  '/_authlayout/_none': typeof AuthlayoutNoneLazyRouteWithChildren
  '/_authlayout/_sidebar': typeof AuthlayoutSidebarLazyRouteWithChildren
  '/_authlayout/_sidebar/': typeof AuthlayoutSidebarIndexRoute
  '/_no-auth/auth-failed/': typeof NoAuthAuthFailedIndexRoute
  '/_authlayout/_header/devices/$id': typeof AuthlayoutHeaderDevicesIdRoute
  '/_authlayout/_none/space/education': typeof AuthlayoutNoneSpaceEducationLazyRoute
  '/_authlayout/_none/registration/': typeof AuthlayoutNoneRegistrationIndexRoute
  '/_authlayout/_none/space/': typeof AuthlayoutNoneSpaceIndexRoute
  '/_authlayout/_none/tos-acceptance/': typeof AuthlayoutNoneTosAcceptanceIndexRoute
  '/_authlayout/_sidebar/allitems/': typeof AuthlayoutSidebarAllitemsIndexRoute
  '/_authlayout/_sidebar/api-keys/': typeof AuthlayoutSidebarApiKeysIndexRoute
  '/_authlayout/_sidebar/branding/': typeof AuthlayoutSidebarBrandingIndexRoute
  '/_authlayout/_sidebar/courses/': typeof AuthlayoutSidebarCoursesIndexRoute
  '/_authlayout/_sidebar/dashboards/': typeof AuthlayoutSidebarDashboardsIndexRoute
  '/_authlayout/_sidebar/devices/': typeof AuthlayoutSidebarDevicesIndexRoute
  '/_authlayout/_sidebar/integrations/': typeof AuthlayoutSidebarIntegrationsIndexRoute
  '/_authlayout/_sidebar/members/': typeof AuthlayoutSidebarMembersIndexRoute
  '/_authlayout/_sidebar/plan-usage/': typeof AuthlayoutSidebarPlanUsageIndexRoute
  '/_authlayout/_sidebar/resources/': typeof AuthlayoutSidebarResourcesIndexRoute
  '/_authlayout/_sidebar/roles-permissions/': typeof AuthlayoutSidebarRolesPermissionsIndexRoute
  '/_authlayout/_sidebar/settings/': typeof AuthlayoutSidebarSettingsIndexRoute
  '/_authlayout/_sidebar/sketches/': typeof AuthlayoutSidebarSketchesIndexRoute
  '/_authlayout/_sidebar/templates/': typeof AuthlayoutSidebarTemplatesIndexRoute
  '/_authlayout/_sidebar/things/': typeof AuthlayoutSidebarThingsIndexRoute
  '/_authlayout/_sidebar/trash/': typeof AuthlayoutSidebarTrashIndexRoute
  '/_authlayout/_sidebar/triggers/': typeof AuthlayoutSidebarTriggersIndexRoute
  '/_authlayout/_header/templates/$id/': typeof AuthlayoutHeaderTemplatesIdIndexRoute
  '/_authlayout/_header/templates/new/': typeof AuthlayoutHeaderTemplatesNewIndexRoute
  '/_authlayout/_header/templates/$id/edit/': typeof AuthlayoutHeaderTemplatesIdEditIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/chrome-app'
    | '/minors'
    | '/'
    | '/auth-failed'
    | '/devices/$id'
    | '/space/education'
    | '/registration'
    | '/space'
    | '/tos-acceptance'
    | '/allitems'
    | '/api-keys'
    | '/branding'
    | '/courses'
    | '/dashboards'
    | '/devices'
    | '/integrations'
    | '/members'
    | '/plan-usage'
    | '/resources'
    | '/roles-permissions'
    | '/settings'
    | '/sketches'
    | '/templates'
    | '/things'
    | '/trash'
    | '/triggers'
    | '/templates/$id'
    | '/templates/new'
    | '/templates/$id/edit'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/chrome-app'
    | '/minors'
    | '/'
    | '/auth-failed'
    | '/devices/$id'
    | '/space/education'
    | '/registration'
    | '/space'
    | '/tos-acceptance'
    | '/allitems'
    | '/api-keys'
    | '/branding'
    | '/courses'
    | '/dashboards'
    | '/devices'
    | '/integrations'
    | '/members'
    | '/plan-usage'
    | '/resources'
    | '/roles-permissions'
    | '/settings'
    | '/sketches'
    | '/templates'
    | '/things'
    | '/trash'
    | '/triggers'
    | '/templates/$id'
    | '/templates/new'
    | '/templates/$id/edit'
  id:
    | '__root__'
    | '/_authlayout'
    | '/_no-auth'
    | '/chrome-app'
    | '/_no-auth/minors'
    | '/_authlayout/_header'
    | '/_authlayout/_none'
    | '/_authlayout/_sidebar'
    | '/_authlayout/_sidebar/'
    | '/_no-auth/auth-failed/'
    | '/_authlayout/_header/devices/$id'
    | '/_authlayout/_none/space/education'
    | '/_authlayout/_none/registration/'
    | '/_authlayout/_none/space/'
    | '/_authlayout/_none/tos-acceptance/'
    | '/_authlayout/_sidebar/allitems/'
    | '/_authlayout/_sidebar/api-keys/'
    | '/_authlayout/_sidebar/branding/'
    | '/_authlayout/_sidebar/courses/'
    | '/_authlayout/_sidebar/dashboards/'
    | '/_authlayout/_sidebar/devices/'
    | '/_authlayout/_sidebar/integrations/'
    | '/_authlayout/_sidebar/members/'
    | '/_authlayout/_sidebar/plan-usage/'
    | '/_authlayout/_sidebar/resources/'
    | '/_authlayout/_sidebar/roles-permissions/'
    | '/_authlayout/_sidebar/settings/'
    | '/_authlayout/_sidebar/sketches/'
    | '/_authlayout/_sidebar/templates/'
    | '/_authlayout/_sidebar/things/'
    | '/_authlayout/_sidebar/trash/'
    | '/_authlayout/_sidebar/triggers/'
    | '/_authlayout/_header/templates/$id/'
    | '/_authlayout/_header/templates/new/'
    | '/_authlayout/_header/templates/$id/edit/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthlayoutRoute: typeof AuthlayoutRouteWithChildren
  NoAuthRoute: typeof NoAuthRouteWithChildren
  ChromeAppRoute: typeof ChromeAppRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthlayoutRoute: AuthlayoutRouteWithChildren,
  NoAuthRoute: NoAuthRouteWithChildren,
  ChromeAppRoute: ChromeAppRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authlayout",
        "/_no-auth",
        "/chrome-app"
      ]
    },
    "/_authlayout": {
      "filePath": "_authlayout.tsx",
      "children": [
        "/_authlayout/_header",
        "/_authlayout/_none",
        "/_authlayout/_sidebar"
      ]
    },
    "/_no-auth": {
      "filePath": "_no-auth.tsx",
      "children": [
        "/_no-auth/minors",
        "/_no-auth/auth-failed/"
      ]
    },
    "/chrome-app": {
      "filePath": "chrome-app.tsx"
    },
    "/_no-auth/minors": {
      "filePath": "_no-auth/minors.tsx",
      "parent": "/_no-auth"
    },
    "/_authlayout/_header": {
      "filePath": "_authlayout/_header.lazy.tsx",
      "parent": "/_authlayout",
      "children": [
        "/_authlayout/_header/devices/$id",
        "/_authlayout/_header/templates/$id/",
        "/_authlayout/_header/templates/new/",
        "/_authlayout/_header/templates/$id/edit/"
      ]
    },
    "/_authlayout/_none": {
      "filePath": "_authlayout/_none.lazy.tsx",
      "parent": "/_authlayout",
      "children": [
        "/_authlayout/_none/space/education",
        "/_authlayout/_none/registration/",
        "/_authlayout/_none/space/",
        "/_authlayout/_none/tos-acceptance/"
      ]
    },
    "/_authlayout/_sidebar": {
      "filePath": "_authlayout/_sidebar.lazy.tsx",
      "parent": "/_authlayout",
      "children": [
        "/_authlayout/_sidebar/",
        "/_authlayout/_sidebar/allitems/",
        "/_authlayout/_sidebar/api-keys/",
        "/_authlayout/_sidebar/branding/",
        "/_authlayout/_sidebar/courses/",
        "/_authlayout/_sidebar/dashboards/",
        "/_authlayout/_sidebar/devices/",
        "/_authlayout/_sidebar/integrations/",
        "/_authlayout/_sidebar/members/",
        "/_authlayout/_sidebar/plan-usage/",
        "/_authlayout/_sidebar/resources/",
        "/_authlayout/_sidebar/roles-permissions/",
        "/_authlayout/_sidebar/settings/",
        "/_authlayout/_sidebar/sketches/",
        "/_authlayout/_sidebar/templates/",
        "/_authlayout/_sidebar/things/",
        "/_authlayout/_sidebar/trash/",
        "/_authlayout/_sidebar/triggers/"
      ]
    },
    "/_authlayout/_sidebar/": {
      "filePath": "_authlayout/_sidebar/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_no-auth/auth-failed/": {
      "filePath": "_no-auth/auth-failed/index.tsx",
      "parent": "/_no-auth"
    },
    "/_authlayout/_header/devices/$id": {
      "filePath": "_authlayout/_header/devices/$id.tsx",
      "parent": "/_authlayout/_header"
    },
    "/_authlayout/_none/space/education": {
      "filePath": "_authlayout/_none/space/education.lazy.tsx",
      "parent": "/_authlayout/_none"
    },
    "/_authlayout/_none/registration/": {
      "filePath": "_authlayout/_none/registration/index.tsx",
      "parent": "/_authlayout/_none"
    },
    "/_authlayout/_none/space/": {
      "filePath": "_authlayout/_none/space/index.tsx",
      "parent": "/_authlayout/_none"
    },
    "/_authlayout/_none/tos-acceptance/": {
      "filePath": "_authlayout/_none/tos-acceptance/index.tsx",
      "parent": "/_authlayout/_none"
    },
    "/_authlayout/_sidebar/allitems/": {
      "filePath": "_authlayout/_sidebar/allitems/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/api-keys/": {
      "filePath": "_authlayout/_sidebar/api-keys/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/branding/": {
      "filePath": "_authlayout/_sidebar/branding/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/courses/": {
      "filePath": "_authlayout/_sidebar/courses/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/dashboards/": {
      "filePath": "_authlayout/_sidebar/dashboards/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/devices/": {
      "filePath": "_authlayout/_sidebar/devices/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/integrations/": {
      "filePath": "_authlayout/_sidebar/integrations/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/members/": {
      "filePath": "_authlayout/_sidebar/members/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/plan-usage/": {
      "filePath": "_authlayout/_sidebar/plan-usage/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/resources/": {
      "filePath": "_authlayout/_sidebar/resources/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/roles-permissions/": {
      "filePath": "_authlayout/_sidebar/roles-permissions/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/settings/": {
      "filePath": "_authlayout/_sidebar/settings/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/sketches/": {
      "filePath": "_authlayout/_sidebar/sketches/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/templates/": {
      "filePath": "_authlayout/_sidebar/templates/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/things/": {
      "filePath": "_authlayout/_sidebar/things/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/trash/": {
      "filePath": "_authlayout/_sidebar/trash/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_sidebar/triggers/": {
      "filePath": "_authlayout/_sidebar/triggers/index.tsx",
      "parent": "/_authlayout/_sidebar"
    },
    "/_authlayout/_header/templates/$id/": {
      "filePath": "_authlayout/_header/templates/$id/index.tsx",
      "parent": "/_authlayout/_header"
    },
    "/_authlayout/_header/templates/new/": {
      "filePath": "_authlayout/_header/templates/new/index.tsx",
      "parent": "/_authlayout/_header"
    },
    "/_authlayout/_header/templates/$id/edit/": {
      "filePath": "_authlayout/_header/templates/$id/edit/index.tsx",
      "parent": "/_authlayout/_header"
    }
  }
}
ROUTE_MANIFEST_END */
