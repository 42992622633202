import { useEffect, useState } from "react"

const useWindowDimensions = (
  innerWidth: number,
  events?: {
    onEnter?: () => void
    onExit?: () => void
  }
) => {
  const [isMobileSize, setIsMobileSize] = useState(window.innerWidth <= innerWidth)

  useEffect(() => {
    const windowResizeHandler = () => {
      const matchMediaString = `(max-width: ${innerWidth}px)`

      if (matchMedia(matchMediaString).matches) {
        setIsMobileSize(v => {
          if (!v && events?.onEnter) events.onEnter()
          return true
        })
      } else {
        setIsMobileSize(v => {
          if (v && events?.onExit) events.onExit()
          return false
        })
      }
    }

    window.addEventListener("resize", windowResizeHandler)
    return () => window.removeEventListener("resize", windowResizeHandler)
  }, [events, innerWidth])

  return isMobileSize
}

export default useWindowDimensions
