import { createFileRoute, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"
import { $rqStorageTemplatesApiClient } from "@/api/storage"
import { getXOrganizationHeaders } from "@/api/utils"
import { MY_SPACE } from "@/constants/spaces"
import { routeGuard } from "@/lib/routing/beforeLoad"

type Tab = "custom" | "arduino"

type ProductSearch = {
  tab?: Tab
}

export const Route = createFileRoute("/_authlayout/_sidebar/templates/")({
  staticData: {
    title: "Templates"
  },
  validateSearch: (search: Record<string, unknown>): ProductSearch => ({
    tab: search.tab === "arduino" || search.tab === "custom" ? search.tab : undefined
  }),
  beforeLoad: async ({ context, search, location }) => {
    const { queryClient, spaceStorage } = context
    const spaces = await queryClient.ensureQueryData(query.space.list)
    const { space } = spaces.find(sp => sp.id === spaceStorage.getSpace()) || MY_SPACE

    if (!search.tab) {
      redirect({
        to: location.pathname,
        throw: true,
        search: {
          tab: space === "pro" ? "custom" : "arduino"
        }
      })
    }
    await routeGuard(context)
  },
  loaderDeps: ({ search }) => ({ tab: search.tab }),
  loader: ({ context: { queryClient, spaceStorage }, deps }) => {
    const space = spaceStorage.getSpace()

    if (deps.tab === "arduino") {
      queryClient.prefetchQuery(query["iot-templates"].list)
    }

    if (deps.tab === "custom") {
      queryClient.prefetchQuery(
        $rqStorageTemplatesApiClient.queryOptions("post", "/storage/template/v1/list", {
          params: { header: getXOrganizationHeaders(space) },
          body: { sort: "asc", show_deleted: false }
        })
      )
      queryClient.prefetchQuery(query.space.members(space))
      queryClient.prefetchQuery(query.boards.list)
    }
  }
})
