import createClient from "openapi-react-query"

import { authXhr, createApiClient, getXOrganizationHeaders } from "@/api/utils"
import { unwrapResponse } from "@/api/utils/NetworkError"
import type { paths as storageMediaPaths } from "@/types/api/storage/media/v1/schema"
import type { paths as storageTemplatesArchivePaths } from "@/types/api/storage/template/archive/v1/schema"
import type { paths as storageTemplatesPaths } from "@/types/api/storage/template/v1/schema"

// Vanilla API clients
export const $storageTemplatesApiClient = createApiClient<storageTemplatesPaths>({
  baseUrl: import.meta.env.VITE_API_MEDIA_URL
})
export const $storageTemplatesArchiveApiClient = createApiClient<storageTemplatesArchivePaths>({
  baseUrl: import.meta.env.VITE_API_MEDIA_URL
})
export const $storageMediaApiClient = createApiClient<storageMediaPaths>({
  baseUrl: import.meta.env.VITE_API_MEDIA_URL
})

// React-Query clients
export const $rqStorageTemplatesApiClient = createClient($storageTemplatesApiClient)
export const $rqStorageTemplatesArchiveApiClient = createClient($storageTemplatesArchiveApiClient)
export const $rqStorageMediaApiClient = createClient($storageMediaApiClient)

/*
 * =====================
 * Manual API functions
 *
 * These functions are manually written and are not generated from the OpenAPI schema.
 * =====================
 */

export async function uploadMedia(xhr: XMLHttpRequest, media: File, spaceID: string) {
  // Using XHR because Fetch API doesn't support request progression yet. See: https://fetch.spec.whatwg.org/#fetch-api
  const formData = new FormData()
  formData.append("media", media, media.name)

  const req = await authXhr(
    xhr,
    {
      method: "POST",
      url: `${import.meta.env.VITE_API_MEDIA_URL}/storage/media/v1`,
      headers: getXOrganizationHeaders(spaceID)
    },
    formData
  )

  const res = await unwrapResponse<{ url: string }>(req)

  return res
}

// Using XHR because Fetch API doesn't support request progression yet. See: https://fetch.spec.whatwg.org/#fetch-api
export async function uploadTemplate(xhr: XMLHttpRequest, template: Blob, spaceID: string) {
  const formData = new FormData()
  formData.append("template", template, "template")

  const req = await authXhr(
    xhr,
    {
      method: "POST",
      url: `${import.meta.env.VITE_API_MEDIA_URL}/storage/template/archive/v1/`,
      headers: getXOrganizationHeaders(spaceID)
    },
    formData
  )

  return unwrapResponse<{
    template_id: string
    message: string
    name: string
  }>(req)
}
