import createClient from "openapi-react-query"

import type { paths as iotPaths } from "@/types/api/iot/schema"

import { createApiClient } from "./utils"

// Vanilla API client
export const $iotApiClient = createApiClient<iotPaths>({ baseUrl: import.meta.env.VITE_API_URL })

// React-Query client
export const $rqIotApiClient = createClient($iotApiClient)
