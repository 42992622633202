import { hasAuthParams } from "@bcmi-labs/art-auth"
import { createRootRouteWithContext, Outlet, redirect, ScrollRestoration, useRouterState } from "@tanstack/react-router"
import { memo } from "react"

import { GlobalElements } from "@/components/helpers/GlobalElements"
import { Flows } from "@/components/modals/flows"
import { WalkthroughArea } from "@/components/walkthroughs"
import { type RouterContext } from "@/router"

// These components are rendered after the initial route is loaded
function AwaitedGlobals() {
  const status = useRouterState({ select: state => state.status })

  if (status === "pending") return null
  return (
    <>
      {/** Global elements portal */}
      <GlobalElements />
      {/** Hidden content */}
      <Flows />
      <WalkthroughArea />
    </>
  )
}

/*
 * ==================
 * Application structure
 * ==================
 */
export default function App() {
  return (
    <div style={{ display: "flex", flex: 1 }}>
      {/** App content */}
      <Outlet />
      <AwaitedGlobals />
      <ScrollRestoration />
    </div>
  )
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: memo(App),
  pendingMs: 200, // Relax the pending state to avoid content flashing
  beforeLoad: async ({ context: { authClient } }) => {
    // Try prefilling token cache
    await authClient.checkSession()

    // If we're seeing auth params in the URL, we're likely coming from an external auth provider
    if (hasAuthParams() && !(await authClient.isAuthenticated())) {
      const appState = new URLSearchParams((await authClient.handleRedirectCallback()).appState)

      // Remove pathname from the query string
      const pathname = appState.get("pathname") || "/"
      if (pathname) appState.delete("pathname")

      // Get all other query params insto a key-value object
      const query = Object.fromEntries(appState)

      redirect({
        to: pathname,
        search: query,
        throw: true
      })
    }
  }
})
