import createClient from "openapi-react-query"

import { createApiClient } from "@/api/utils"
import type { paths as groupsFoldersPaths } from "@/types/api/groups/v1/folders/schema"
import type { paths as groupsViewsPaths } from "@/types/api/groups/v1/views/schema"

// Vanilla API client
export const $groupsViewApiClient = createApiClient<groupsViewsPaths>({
  baseUrl: import.meta.env.VITE_API_URL,
  querySerializer: { array: { style: "form", explode: false } }
})
export const $groupsFoldersApiClient = createApiClient<groupsFoldersPaths>({
  baseUrl: import.meta.env.VITE_API_URL,
  querySerializer: { array: { style: "form", explode: false } }
})

// React-Query client
export const $rqViewsApiClient = createClient($groupsViewApiClient)
export const $rqFoldersApiClient = createClient($groupsFoldersApiClient)
