import { BaseModal, Loader, ModalBody, ModalContent, ModalHeader } from "@bcmi-labs/art-ui/components"
import { betterLazy } from "@bcmi-labs/art-ui/utils"
import { Fragment, Suspense, useCallback, useState } from "react"

import { FlowManagerContext, type FlowPropsMapper } from "@/components/helpers/Flow"
import { useOnChange } from "@/lib/hooks/useOnChange"
import { useModalStore } from "@/store/modals"

// Lazy load all the flow steps.
const ProSpaceNameNodal = betterLazy(
  () => import("@/components/modals/flows/ProSpace/ProSpaceNameModal/ProSpaceNameModal")
)

export const ProSpaceFlowSteps = {
  name: ProSpaceNameNodal
} as const

// Declare context methods for each flow step.
const propsMap: FlowPropsMapper<typeof ProSpaceFlowSteps> = {
  name: {
    next: () => {
      useModalStore.getState().setProSpaceFlowStep(undefined)
    }
  }
}

/**
 * ProSpaceFlow manager
 */
export function ProSpaceFlow() {
  const proSpaceFlow = useModalStore(state => state.proSpaceFlow)
  const setProSpaceFlowStep = useModalStore(state => state.setProSpaceFlowStep)

  const Comp = proSpaceFlow.step ? ProSpaceFlowSteps[proSpaceFlow.step] : Fragment

  const [prefetched, setPrefetched] = useState(false)
  const [open, setOpen] = useState(!!proSpaceFlow.step)

  useOnChange(!!proSpaceFlow.step, (_, curr) => {
    setOpen(curr)
    // If we moved to true and the agent is not created, create it.
    if (curr) {
      if (!prefetched)
        Promise.all(Object.values(ProSpaceFlowSteps).map(step => step.prefetch())).then(() => setPrefetched(true))
    }
  })

  const closeFlow = useCallback(() => {
    setProSpaceFlowStep(undefined)
  }, [setProSpaceFlowStep])

  return (
    <FlowManagerContext ctx={{ close: () => closeFlow(), reset: () => closeFlow() }}>
      <BaseModal open={open} onOpenChange={setOpen} contentProps={{ onCloseAutoFocus: closeFlow }}>
        <Suspense
          fallback={
            <ModalContent style={{ minHeight: "300px" }}>
              <ModalHeader title="Loading flow..." />
              <ModalBody>
                <div className="flex-center">
                  <Loader />
                </div>
              </ModalBody>
            </ModalContent>
          }>
          {proSpaceFlow.step ? <Comp {...propsMap[proSpaceFlow.step]} /> : null}
        </Suspense>
      </BaseModal>
    </FlowManagerContext>
  )
}
