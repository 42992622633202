import { createFileRoute } from "@tanstack/react-router"

import { $rqViewsApiClient } from "@/api/groups"
import { $rqIotApiClient } from "@/api/iot"
import { query } from "@/api/query"
import { getXOrganizationHeaders } from "@/api/utils"
import { routeGuard } from "@/lib/routing/beforeLoad"

type SearchParams = {
  forcesearch?: boolean
}

export const Route = createFileRoute("/_authlayout/_sidebar/things/")({
  staticData: {
    title: "Things"
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    forcesearch: typeof search.forcesearch === "boolean" ? search.forcesearch : undefined
  }),
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.prefetchQuery(query.space.members(space))
    queryClient.prefetchQuery($rqIotApiClient.queryOptions("get", "/iot/v2/timezones"))

    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/things", {
        params: { header: getXOrganizationHeaders(space) }
      })
    )

    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/devices", {
        params: { header: getXOrganizationHeaders(space) }
      })
    )
    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/sketches", {
        params: { header: getXOrganizationHeaders(space) }
      })
    )
  }
})
