import { createFileRoute } from "@tanstack/react-router"

import { query } from "@/api/query"
import { $rqStorageTemplatesApiClient } from "@/api/storage"
import { getXOrganizationHeaders } from "@/api/utils"
import { routeGuard } from "@/lib/routing/beforeLoad"

type SearchParams = {
  forcesearch?: boolean
}

export const Route = createFileRoute("/_authlayout/_sidebar/triggers/")({
  staticData: {
    title: "Triggers"
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    forcesearch: typeof search.forcesearch === "boolean" ? search.forcesearch : undefined
  }),
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.ensureQueryData(
      $rqStorageTemplatesApiClient.queryOptions("post", "/storage/template/v1/list", {
        params: { header: getXOrganizationHeaders(space) },
        body: { sort: "asc", show_deleted: false }
      })
    )
    queryClient.ensureQueryData(query.trigger.history(space))
    queryClient.ensureQueryData(query.space.members(space))
  }
})
