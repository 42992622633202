import { MY_CLOUD_SPACE } from "@bcmi-labs/cloud-sidebar"
import { queryOptions } from "@tanstack/react-query"

import { getSpaceKits, getSpacesClean, getSpacesInviteCode, getUsersList } from "@/api/classroom"
import { getKits } from "@/api/education"
import { $iotApiClient } from "@/api/iot"
import { getTriggersHistoryList } from "@/api/notifications"
import { authFetch, getXOrganizationHeaders } from "@/api/utils"
import { unwrapResponse } from "@/api/utils/NetworkError"
import { MY_SPACE } from "@/constants/spaces"
import type { Type } from "@/constants/things"
import type { Board, Space, Subscriptions, Template, TemplateCardType, VariableType } from "@/types"

// TODO: Decide how we plan on supporting weird APIs like these.

/**
 * Centralized query functions
 * Every object exports the relative query function and query key.
 */
export const query = {
  /** @deprecated Deprecating away: use the $rq* clients instead. */
  device: {
    iotList: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`iot/devices/list/${id}`],
        queryFn: async ({ signal }) => {
          const res = await $iotApiClient.GET("/iot/v2/devices", {
            signal,
            headers: getXOrganizationHeaders(id)
          })
          return res.data!
        }
      })
  },
  /** @deprecated Deprecating away: use the $rq* clients instead. */
  dashboard: {
    iotList: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`iot/dashboard/list/${id}`],
        queryFn: async ({ signal }) => {
          const res = await $iotApiClient.GET("/iot/v2/dashboards", {
            signal,
            headers: getXOrganizationHeaders(id)
          })
          return res.data!
        }
      })
  },
  /** @deprecated Deprecating away: use the $rq* clients instead. */
  thing: {
    iotList: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`iot/things/list/${id}`],
        queryFn: async ({ signal }) => {
          const res = await $iotApiClient.GET("/iot/v2/things", {
            signal,
            headers: getXOrganizationHeaders(id),
            params: { query: { show_properties: true } }
          })
          return res.data!
        }
      })
  },
  /** @deprecated Deprecating away: use the $rq* clients instead. */
  trigger: {
    iotList: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`iot/trigger/list/${id}`],
        queryFn: async ({ signal }) => {
          const res = await $iotApiClient.GET("/iot/v1/triggers", {
            signal,
            headers: getXOrganizationHeaders(id)
          })
          return res.data!
        }
      }),
    // ! This endpoint does not have a schema.
    history: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`triggers/history/${id}`],
        queryFn: () => getTriggersHistoryList(id)
      })
  },
  space: {
    list: queryOptions({
      queryKey: [`spaces/list`],
      queryFn: () => getSpacesClean()
    }),
    // TODO: Figure out a way to prevent errors from being thrown when an enterprise space is provided
    kits: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`spaces/kits/${id}`],
        queryFn: () => (id === MY_CLOUD_SPACE.id ? getKits() : getSpaceKits(id))
      }),
    subscriptions: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`spaces/subscriptions/${id}`],
        queryFn: async () => {
          const response = await authFetch(`${import.meta.env.VITE_API2_URL}/restrictions/v1/recap/me`, {
            headers: getXOrganizationHeaders(id)
          })
          return unwrapResponse<Subscriptions>(response)
        }
      }),
    /** Retrieves the list of members for the given space. */
    members: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`members/list/${id}`],
        queryFn: () => getUsersList(id)
      }),
    /** Retrieves the invite code for the given space. */
    inviteCode: (id: Space["id"]) =>
      queryOptions({
        queryKey: [`space/code/${id}`],
        queryFn: () => getSpacesInviteCode(id)
      })
  },
  user: {
    /** Retrieves the list of subscriptions for the current user. */
    subscriptions: queryOptions({
      queryKey: [`subscriptions`],
      queryFn: async () => {
        const response = await authFetch(`${import.meta.env.VITE_API2_URL}/restrictions/v1/recap/me`, {
          headers: getXOrganizationHeaders(MY_SPACE.id)
        })
        return unwrapResponse<Subscriptions>(response)
      }
    })
  },
  // ! This endpoint does not have a schema.
  "iot-templates": {
    /** Retrieves the list of IoT templates. */
    list: queryOptions({
      queryKey: [`iot-templates/list`],
      queryFn: async () =>
        unwrapResponse<TemplateCardType[]>(await authFetch(`${import.meta.env.VITE_TEMPLATE_URL}/templates.json`))
    }),
    /** Retrieves the requested IoT template. */
    single: (name: string) =>
      queryOptions({
        queryKey: [`iot-templates/single/${name}`],
        queryFn: async () =>
          unwrapResponse<Template>(
            await authFetch(`${import.meta.env.VITE_TEMPLATE_URL}/templates/${name}/template.json`)
          )
      })
  },
  /**
   * Boards API.
   * By default, these queries will never be stale.
   */
  boards: {
    list: queryOptions({
      queryKey: [`boards`],
      queryFn: async () => {
        const response = await authFetch(`${import.meta.env.VITE_BOARDS_API_URL}`)
        const boardlist = (await unwrapResponse<{ items: Board[] }>(response)).items

        const defaultBoardDefs: Record<string, Board> = {
          "device:generic:manual": {
            name: "Manually configured device"
          } as Board
        }

        return boardlist.reduce((acc, board) => {
          acc[board.fqbn] = board
          return acc
        }, defaultBoardDefs)
      },
      staleTime: Infinity
    }),
    single: (vendorId: string, productId: string) =>
      queryOptions({
        queryKey: [`boards/vendor-${vendorId}/product-${productId}`],
        queryFn: async () => {
          const response = await authFetch(`${import.meta.env.VITE_BOARDS_API_URL}/byVidPid/${vendorId}/${productId}`)
          return unwrapResponse<Board>(response)
        },
        staleTime: Infinity
      })
  },
  other: {
    /** Retrieves the list of countries.
     * By default, this query will never be stale.
     */
    countries: queryOptions({
      queryKey: [`countries`],
      queryFn: async () => {
        try {
          const res = await fetch(`${import.meta.env.VITE_API2_URL}/countries/v1`)
          const countries = await unwrapResponse<{ code: string; name: string }[]>(res)

          // Rname code and name to value and label
          return countries.map(({ code, name }) => ({ value: code, label: name }))
        } catch (error) {
          console.error(error)
          return []
        }
      },
      staleTime: Infinity
    }),
    property_types: queryOptions({
      queryKey: [`property_types`],
      queryFn: async () =>
        unwrapResponse<VariableType[]>(
          await authFetch(`${import.meta.env.VITE_API2_URL}/iot/v1/property_types`, {
            method: "GET"
          })
        ),
      select: response => response.reduce((o, i) => ({ ...o, [i.type]: i }), {} as Partial<Record<Type, VariableType>>),
      staleTime: Infinity
    })
  }
}

/*
 * =============================================================================
 * Query utilities
 * ============================================================================
 */
