import { useAuth } from "@bcmi-labs/art-auth"
import { Portal } from "@radix-ui/react-portal"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

import { MY_SPACE } from "@/constants/spaces"
import { useSpace } from "@/lib/hooks/useSpace"

import styles from "./index.module.scss"

export function GlobalElements() {
  const { client } = useAuth()
  const { space } = useSpace()

  // Customized spaces don't get to enjoy the walkthrough/global modals on the private space.
  if (client.getCustomization() && space.id === MY_SPACE.id) return null

  return <Portal id="GlobalElementsBannerPortal" className={styles.GlobalElementsBannerPortal} />
}

export function GlobalElementsBannerPortal(props: { children: React.ReactNode }) {
  const { children } = props
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  // Prevent render if not mounted.
  if (!mounted) return null

  const _container = document.getElementById("GlobalElementsBannerPortal")

  if (!_container) console.warn(`GlobalElementsBannerPortal: Could not find "global-elements--bannerzone" element. `)

  return _container ? createPortal(children, _container) : null
}
