import { cx } from "cva"
import type { ComponentProps } from "react"

import styles from "./index.module.scss"

export function NoneLayout(props: ComponentProps<"div">) {
  const { children, className, ...rest } = props

  return (
    <div className={cx(styles.None, className)} {...rest}>
      <div className={styles.None__content}>{children}</div>
    </div>
  )
}
