import type { Arrayable } from "type-fest"

import { AuthClientInstance } from "@/lib/services/instances"
import type { AssetType } from "@/types"

// Event types as defined in https://arduino.atlassian.net/wiki/spaces/ANA/pages/3378774022/Arduino+Web+Tracking+Guidelines
// If undefined, no extra payload is required beyond the automatically enriched one.
type TrackingEvent = {
  PAGEVIEW: {
    page_title: string
  }
  // 1.22
  CREATE_FOLDER: {
    type: "menu" | "modal"
  }
  // 1.23
  MOVE_ITEMS: {
    type: "drag&drop" | "modal"
    assets: Array<{ id: string; type: AssetType }>
  }
  // 1.24
  VIEW_FOLDER: {
    id: "root" | (string & {})
    assets: Array<{ id: string; type: AssetType }>
  }
  // 1.25
  TRASH_INTERACTION: {
    action: "delete" | "restore"
  }
  // 4.2 (we can't track thing_id)
  THING_CREATE: undefined
  // 4.3
  THING_DELETE: {
    thing_id: string
  }
  // 4.7
  DASHBOARD_CREATE: undefined
  // 4.8
  API_KEY_CREATE: undefined
  // 4.9
  TEMPLATE_SELECT: {
    name: string
    link_url: string
  }
  // No code
  TEMPLATE_CREATE: {
    type: "new" | "imported"
  }
  TEMPLATE_GENERATE: {
    assets: Arrayable<"thing" | "dashboard" | "trigger">
    documentation: Arrayable<"description" | "schematics">
  }
  // 5.5
  SKETCH: {
    action: "create sketch" | "sketch_rename" | "sketch_download" | "sketch_share" | "sketch_delete"
    sketch_id: string
  }
  // 5.7
  SKETCH_NEW_FOLDER: undefined
  // No code
  TRIGGER_CREATE: undefined
  // No code
  BRANDING_SAVE: {
    company_subdomain: string
    company_name: string
  }
  // No code: walkthrough key
  WALKTHROUGH_TEMPLATE_CLICK: {
    type: "dismiss" | "try_now"
  }
}

/** Enrich the payload with the correct data based on the event */
function payloadEnricher(event: keyof TrackingEvent) {
  switch (event) {
    case "PAGEVIEW":
      return {
        event: "virtualPageView",
        page: {
          type: "content",
          title: document.title,
          path: window.location.href,
          environment: import.meta.env.VITE_HF_CDN_ENV,
          region: "global",
          brand: "arduino",
          language: "EN"
        }
      }
    case "API_KEY_CREATE":
      return { event: "iot_cloud_integration", action: "create api key" }
    case "CREATE_FOLDER":
      return { event: "folder_create" }
    case "MOVE_ITEMS":
      return { event: "items_moved" }
    case "VIEW_FOLDER":
      return { event: "folder_viewed" }
    case "TRASH_INTERACTION":
      return { event: "trash_interaction" }
    case "THING_DELETE":
      return { event: "iot_cloud_deletething" }
    case "THING_CREATE":
      return { event: "iot_cloud_creatething" }
    case "TEMPLATE_SELECT":
      return { event: "select_template" }
    case "TEMPLATE_CREATE":
      return { event: "create_template" }
    case "TEMPLATE_GENERATE":
      return { event: "generate_template" }
    case "DASHBOARD_CREATE":
      return { event: "iot_cloud_dashboard", action: "create dashboard" }
    case "TRIGGER_CREATE":
      return { event: "iot_cloud_trigger", action: "create trigger" }
    case "SKETCH_NEW_FOLDER":
      return { event: "editor_folder_add" }
    case "SKETCH":
      return { event: "editor_sketch_interaction" }
    case "BRANDING_SAVE":
      return { event: "save_branding" }
    case "WALKTHROUGH_TEMPLATE_CLICK":
      return { event: "walkthrough_template_click" }
    default:
      return {}
  }
}

type EmitEvent<K extends keyof TrackingEvent> =
  TrackingEvent[K] extends Record<string, unknown>
    ? { type: K; payload: TrackingEvent[K] }
    : { type: K; payload?: undefined }

/** GA4 event emitter */
export async function ga4Emitter<K extends keyof TrackingEvent>({ type, payload = undefined }: EmitEvent<K>) {
  const user = await AuthClientInstance.getUser()

  if (!user) return // If the user is not logged in, don't track anything

  try {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      page_location: window.location.href,
      page_title: document.title,
      ...payloadEnricher(type),
      ...payload,
      site_area: "app_arduino",
      ...(user["http://arduino.cc/is_minor"] && { userkind: "minor" }),
      user: {
        id: user["http://arduino.cc/id"],
        email: user.email
      },
      user_id: user["http://arduino.cc/id"]
    })
  } catch {
    console.error(`Error tracking event [${type}] with args [${payload}]`)
  }
}
