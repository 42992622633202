export type FeatureFlag<T extends boolean | number = boolean | number> = {
  description: string
  options: {
    label: string
    value: T
  }[]
}

const availableFeatureFlags = {
  "enable-view-transition": {
    description: "Enable View Transition APIs (requires refresh)",
    options: [
      { label: "Enabled", value: true },
      { label: "Disabled", value: false }
    ]
  },
  "table-delay-first-selection": {
    description: "Add a delay to the first selection in the table component to allow for double-clicking.",
    options: [
      { label: "Disabled", value: false },
      { label: "200ms", value: 200 },
      { label: "250ms", value: 250 },
      { label: "300ms", value: 300 },
      { label: "350ms", value: 350 },
      { label: "400ms", value: 400 },
      { label: "450ms", value: 450 },
      { label: "500ms", value: 500 }
    ]
  },
  "table-hover-preload": {
    description: "Enable route preloading on hover for internal links in the table component.",
    options: [
      { label: "Enabled", value: true },
      { label: "Disabled", value: false }
    ]
  }
} as const satisfies Record<string, FeatureFlag>

const defaultFeatureFlags: {
  [K in keyof typeof availableFeatureFlags]: (typeof availableFeatureFlags)[K]["options"][number]["value"]
} = {
  "enable-view-transition": false,
  "table-delay-first-selection": false,
  "table-hover-preload": false
}

export const featureFlags = Object.keys(availableFeatureFlags).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      ...availableFeatureFlags[key as keyof typeof availableFeatureFlags],
      default: defaultFeatureFlags[key as keyof typeof defaultFeatureFlags]
    }
  }),
  {} as {
    [K in keyof typeof availableFeatureFlags]: (typeof availableFeatureFlags)[K] & {
      readonly default: (typeof defaultFeatureFlags)[K]
    }
  }
)
