import { useDeviceSelectors } from "react-device-detect"

import { GlobalElementsBannerPortal } from "@/components/helpers/GlobalElements"
import { GetStartedFlow } from "@/components/modals/flows/GetStarted"
import { JoinSpaceFlow } from "@/components/modals/flows/JoinSpace"
import { ProSpaceFlow } from "@/components/modals/flows/ProSpace"

export function Flows() {
  // This component never re-renders, so this is stable even when the UA changes.
  const [{ isDesktop }] = useDeviceSelectors(window.navigator.userAgent)

  return (
    <GlobalElementsBannerPortal>
      <ProSpaceFlow />
      <JoinSpaceFlow />
      {isDesktop ? <GetStartedFlow /> : null}
    </GlobalElementsBannerPortal>
  )
}
