import { MY_CLOUD_SPACE } from "@bcmi-labs/cloud-sidebar"
import { createFileRoute, notFound, redirect } from "@tanstack/react-router"
import type { MethodResponse } from "openapi-react-query"

import { $rqFoldersApiClient } from "@/api/groups"
import { $rqIotApiClient } from "@/api/iot"
import { $rqOtaApiClient } from "@/api/ota"
import { getXOrganizationHeaders } from "@/api/utils"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_header/devices/$id")({
  staticData: {
    onSpaceChange: () =>
      redirect({
        to: "/devices",
        throw: true
      })
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: async ({ context: { queryClient, spaceStorage, headManager }, params, preload }) => {
    const space = spaceStorage.getSpace()

    try {
      // TODO: Remove when https://github.com/openapi-ts/openapi-typescript/pull/1950 is merged
      const deviceData: MethodResponse<typeof $rqIotApiClient, "get", "/iot/v2/devices/{id}"> =
        await queryClient.ensureQueryData(
          $rqIotApiClient.queryOptions("get", `/iot/v2/devices/{id}`, {
            params: { header: getXOrganizationHeaders(space), path: { id: params.id } }
          })
        )

      // Prefetch folder info
      queryClient.prefetchQuery(
        $rqFoldersApiClient.queryOptions("get", "/groups/v1/folders", {
          params: { header: getXOrganizationHeaders(space), query: { child_id: params.id } }
        })
      )

      // Try prefetching the OTA list query, but don't wait for it or error out.
      if (deviceData.ota_compatible) {
        queryClient.prefetchQuery(
          $rqOtaApiClient.queryOptions("get", "/v1/ota", {
            params: {
              header: getXOrganizationHeaders(space),
              query: { device_id: params.id, limit: 500, order: "desc" }
            }
          })
        )
      }

      if (deviceData.organization_id !== space && space !== MY_CLOUD_SPACE.id) notFound({ throw: true })

      if (!preload) {
        headManager.push({
          title: `${deviceData.name} | Arduino Cloud`
        })
      }
    } catch {
      // Whatever happens, we want to throw a 404.
      notFound({ throw: true })
    }
  }
})
