import { createContext, useContext } from "react"
import type { Simplify } from "type-fest"

export type FlowActions<T = undefined> = {
  /** Previous flow */
  prev?: () => void
  /** Next flow */
  next: T extends undefined ? (args?: T) => void : (args: T) => void
}

// Ensure that components extend the needed set.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FlowPropsMapper<T extends Record<string, React.ComponentType<any>>> = {
  [K in keyof T]: T[K] extends React.ComponentType<infer P>
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      P extends FlowActions<any>
      ? // ? Unsure about this, but the prettify makes types easier to hover!
        Simplify<React.ComponentProps<T[K]>>
      : never
    : never
}

/**
 * Stub function to throw an error if the flow context is not provided.
 * @ignore
 */
const stub = () => {
  throw new Error("You forgot to wrap your component in <FlowContext>.")
}

// ? Is this a hack? I am not sure. However now you can type the flow consumer component. Just don't fuck up when you're using it.
export type FlowContext = {
  /** Close the flow. */
  close: () => void
  reset: () => void
}

export const defaultFlowContext: FlowContext = {
  close: stub,
  reset: stub
}

export const FlowContext = createContext<FlowContext>(defaultFlowContext)

/**
 * Custom hook to access the flow context. Allows you to navigate between steps in the flow.
 */
export function useFlowManager() {
  const context = useContext(FlowContext)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!context) {
    throw new Error("useFlow must be used within a FlowContext")
  }
  return context
}

/**
 * FlowManager Context
 */
export function FlowManagerContext(props: {
  /* Context to pass to the flow. */
  ctx: FlowContext
  children: React.ReactNode
}) {
  const { children, ctx } = props

  return <FlowContext.Provider value={ctx}>{children}</FlowContext.Provider>
}
