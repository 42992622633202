import Daemon from "arduino-create-agent-js-client"
import { create } from "zustand"

interface Store {
  agent: Daemon | null
  createAgent: () => Daemon
  destroyAgent: () => void
}
/**
 * Store for the agent instance.
 */
export const useAgentStore = create<Store>()((set, get, _) => ({
  agent: null,
  createAgent: () => {
    if (!get().agent) {
      // ! This doesn't support the new CrOS extension with Web Serial API.
      // ! In order to add support we'll need to polyfill buffer and ensure that process.env is defined so that the Uploader can work.
      const agent = new Daemon(import.meta.env.VITE_BOARDS_API_URL, {
        chromeExtensionid: import.meta.env.VITE_CHROME_EXTENSION_ID,
        useWebSerial: false
      })
      set({ agent })
    }
    return get().agent!
  },
  destroyAgent: () => {
    set({ agent: null })
  }
}))
