import { BaseModal, Loader, ModalBody, ModalContent, ModalHeader } from "@bcmi-labs/art-ui/components"
import { betterLazy } from "@bcmi-labs/art-ui/utils"
import { Suspense, useCallback, useState } from "react"

import { FlowManagerContext, type FlowPropsMapper } from "@/components/helpers/Flow"
import { useOnChange } from "@/lib/hooks/useOnChange"
import { useAgentStore } from "@/store/agent"
import { useModalStore } from "@/store/modals"

// Lazy load all the flow steps.
const GetStarted = betterLazy(() => import("@/components/modals/flows/GetStarted/GetStarted"))
const Installing = betterLazy(() => import("@/components/modals/flows/GetStarted/Installing"))
const DownloadAgent = betterLazy(() => import("@/components/modals/flows/GetStarted/DownloadAgent"))
const MobileQR = betterLazy(() => import("@/components/modals/flows/GetStarted/MobileQR"))
const NoDevice = betterLazy(() => import("@/components/modals/flows/GetStarted/NoDevice"))
const PlugDevice = betterLazy(() => import("@/components/modals/flows/GetStarted/PlugDevice"))
const DeviceError = betterLazy(() => import("@/components/modals/flows/GetStarted/DeviceError"))
const DeviceDetected = betterLazy(() => import("@/components/modals/flows/GetStarted/DeviceDetected"))

export const getStartedFlowSteps = {
  start: GetStarted,
  downloadagent: DownloadAgent,
  installing: Installing,
  plugdevice: PlugDevice,
  mobileqr: MobileQR,
  nodevice: NoDevice,
  devicedetected: DeviceDetected,
  deviceerror: DeviceError
} as const

// Declare context methods for each flow step.
// TODO: Maybe make this a function that takes the context as an argument, so it gets in the scope and can be used in the flow steps.
const propsMap: FlowPropsMapper<typeof getStartedFlowSteps> = {
  start: {
    next: ({ hasAgent }) => {
      useModalStore.getState().setGetStartedFlowStep(hasAgent ? "plugdevice" : "downloadagent")
    }
  },
  downloadagent: {
    prev: () => useModalStore.getState().setGetStartedFlowStep("start"),
    next: () => useModalStore.getState().setGetStartedFlowStep("installing")
  },
  installing: {
    prev: () => useModalStore.getState().setGetStartedFlowStep("downloadagent"),
    next: () => useModalStore.getState().setGetStartedFlowStep("plugdevice")
  },
  plugdevice: {
    next: async ({ board, device }) => {
      useModalStore.getState().setGetStartedFlowStore({ board, serial: device })
      useModalStore.getState().setGetStartedFlowStep("devicedetected")
    }
  },
  mobileqr: {
    next: () => useModalStore.getState().setGetStartedFlowStep(undefined)
  },
  nodevice: {
    next: () => useModalStore.getState().setGetStartedFlowStep("plugdevice")
  },
  devicedetected: {
    prev: () => {
      useModalStore.getState().setGetStartedFlowStore(undefined)
      useModalStore.getState().setGetStartedFlowStep("plugdevice")
    },
    next: () => useModalStore.getState().setGetStartedFlowStep(undefined)
  },
  deviceerror: {
    next: () => useModalStore.getState().setGetStartedFlowStep(undefined)
  }
}

function renderChildren(step: keyof typeof getStartedFlowSteps | undefined) {
  if (!step) return null
  const Comp = getStartedFlowSteps[step]
  // @ts-expect-error - This is fine, we're just passing the props to the component.
  return <Comp {...propsMap[step]} />
}

/**
 * GetStartedFlow manager
 */
export function GetStartedFlow() {
  const getStartedFlow = useModalStore(s => s.getStartedFlow)
  const setGetStartedFlowStep = useModalStore(s => s.setGetStartedFlowStep)
  const setGetStartedFlowStore = useModalStore(s => s.setGetStartedFlowStore)

  const agent = useAgentStore(s => s.agent)
  const createAgent = useAgentStore(s => s.createAgent)

  const [prefetched, setPrefetched] = useState(false)
  const [open, setOpen] = useState(!!getStartedFlow.step)

  useOnChange(!!getStartedFlow.step, (_, curr) => {
    setOpen(curr)
    // If we moved to true and the agent is not created, create it.
    if (curr) {
      if (!agent) {
        createAgent()
      }
      if (!prefetched)
        Promise.all(Object.values(getStartedFlowSteps).map(step => step.prefetch())).then(() => setPrefetched(true))
    }
  })

  const closeFlow = useCallback(() => {
    setGetStartedFlowStep(undefined)
    setGetStartedFlowStore(undefined)
  }, [setGetStartedFlowStep, setGetStartedFlowStore])

  return (
    <FlowManagerContext
      ctx={{
        close: closeFlow,
        reset: () => {
          setGetStartedFlowStep("start")
          setGetStartedFlowStore(undefined)
        }
      }}>
      <BaseModal open={open} onOpenChange={setOpen} contentProps={{ onCloseAutoFocus: closeFlow }}>
        <Suspense
          fallback={
            <ModalContent style={{ minHeight: "300px" }}>
              <ModalHeader title="Loading flow..." />
              <ModalBody>
                <div className="flex-center">
                  <Loader />
                </div>
              </ModalBody>
            </ModalContent>
          }>
          {agent ? (
            renderChildren(getStartedFlow.step)
          ) : (
            <ModalContent>
              <ModalHeader title="Loading agent..." />
              <ModalBody>
                <div className="flex-center">
                  <Loader />
                </div>
              </ModalBody>
            </ModalContent>
          )}
        </Suspense>
      </BaseModal>
    </FlowManagerContext>
  )
}
