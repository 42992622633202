import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

const LATEST_VERSION = 1

type RecentItem = {
  id: string
  type: "THING" | "SKETCH" | "DASHBOARD" | "TRIGGER" | "DEVICE"
  user_id: string
  name: string
  created_at: string
  open_at: string
  space_id: string
  /* The following properties are optional and are only used for certain types of items. */
  relations?: {
    thing_id?: string
  }
}

interface Store {
  recents: { userid: string; data: RecentItem[] }
  /** Update an existing item, if present. Returns if the item was patched. */
  patchRecent: (
    id: RecentItem["id"],
    type: RecentItem["type"],
    value: Partial<Omit<RecentItem, "id" | "type">>
  ) => boolean
  /** Add a new item to the recents list. */
  addRecent: (item: Omit<RecentItem, "open_at">) => void
  /** Remove an item from the recents list. */
  removeRecent: (id: RecentItem["id"]) => void
}

export const useRecentsStore = create<Store>()(
  persist(
    (set, get, _) => ({
      recents: { userid: "", data: [] },
      patchRecent: (id, type, value) => {
        const existing = get().recents.data.findIndex(i => i.id === id && i.type === type)

        // No need to patch if the item doesn't exist
        if (existing === -1) return false

        // Patch the item
        set(state => ({
          recents: {
            ...state.recents,
            data: [
              ...state.recents.data.slice(0, existing),
              { ...state.recents.data[existing]!, ...value },
              ...state.recents.data.slice(existing + 1)
            ]
          }
        }))
        return true
      },
      addRecent: newItem => {
        // Check if a patch is needed
        const hasPatched = get().patchRecent(newItem.id, newItem.type, {
          name: newItem.name,
          created_at: newItem.created_at,
          space_id: newItem.space_id,
          relations: newItem.relations,
          user_id: newItem.user_id,
          open_at: new Date().toISOString()
        })

        // Item has been patched, no need to add it to the list
        if (hasPatched) return

        set(state => {
          // If we have more than 30 items, remove the oldest one
          if (state.recents.data.length > 30) {
            state.recents.data.pop()
          }

          return {
            recents: {
              ...state.recents,
              data: [{ ...newItem, open_at: new Date().toISOString() }, ...state.recents.data]
            }
          }
        })
      },
      removeRecent: id =>
        set(state => ({
          ...state,
          recents: {
            ...state.recents,
            data: state.recents.data.filter(i => i.id !== id)
          }
        }))
    }),
    {
      name: "cloudhome-recents",
      storage: createJSONStorage(() => localStorage),
      version: LATEST_VERSION,
      migrate: (persistedState, version) => {
        if (version !== LATEST_VERSION) {
          console.warn("Recents store version mismatch, running migration...")
          return versionMigrator(persistedState as Store, version)
        }
        return persistedState
      }
    }
  )
)

function versionMigrator(persistedState: Store, version: number) {
  // Select migrator based on version.
  switch (version) {
    case 0:
      // Version 1 removed erroneously created sketch folders
      return {
        ...persistedState,
        recents: {
          ...persistedState.recents,
          data: persistedState.recents.data.filter(i => !i.id.includes("/sketches_v2"))
        }
      }
    default:
      return persistedState
  }
}
