import type { AnyRouter } from "@tanstack/react-router"
import { lazy, Suspense, useEffect, useState } from "react"
import * as config from "virtual:config"

const DevtoolsToolbar = lazy(() =>
  import("./toolbar").then(res => ({
    default: res.DevtoolsToolbar
  }))
)

export function Devtools({ router }: { router: AnyRouter }) {
  const [showDevtools, setShowDevtools] = useState(config.mode === "development")

  useEffect(() => {
    window.toggleDevtools = () => setShowDevtools(old => !old)
  }, [])

  if (!showDevtools) return null

  return (
    <Suspense>
      <DevtoolsToolbar router={router} />
    </Suspense>
  )
}
