export function loadScript(src: string, id: string, async = false) {
  return new Promise((res, rej) => {
    if (document.getElementById(id)) return

    const script = document.createElement("script")
    script.id = id
    script.src = src
    script.async = async
    document.head.append(script)
    script.onload = res
    script.onerror = rej
  })
}

export function loadCSS(href: string) {
  const fileref = document.createElement("link")
  fileref.rel = "stylesheet"
  fileref.type = "text/css"
  fileref.href = href
  document.getElementsByTagName("head")[0]?.appendChild(fileref)
}

/**
 * Formats a date string to a human-readable format
 *
 * @param date
 * @param opts
 * @returns
 */
export function formatDate(date: Date | string | undefined, opts?: Intl.DateTimeFormatOptions) {
  const dateObj = date ? new Date(date) : new Date()

  // Not a date, or before epoch.
  if (Number.isNaN(dateObj.getTime()) || dateObj.getTime() <= 0) return "-"

  try {
    return new Intl.DateTimeFormat(
      navigator.language || "it-IT",
      opts || {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric"
      }
    ).format(dateObj)
  } catch {
    return "-"
  }
}
/**
 * Downloads a file from a blob
 *
 * @param blob
 * @param filename
 */
export function downloadFile(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.setAttribute("href", url)
  link.setAttribute("download", `${filename}`)
  link.style.visibility = "hidden"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * Converts bytes to a human-readable format
 * @param bytes
 * @param decimals
 * @returns
 */
export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["B", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export function isUUIDFormat(str: string) {
  const uuidFormatRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return uuidFormatRegex.test(str)
}

export function prettifyLabel(label: string, items = 0): string {
  return !items || items > 1 ? `${label}s` : label
}
