import { createFileRoute } from "@tanstack/react-router"

import { query } from "@/api/query"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_header/templates/new/")({
  staticData: {
    title: "New Template"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient } }) => {
    // const space = spaceStorage.getSpace()

    /*     queryClient.ensureQueryData(query.thing.list(space))
    queryClient.ensureQueryData(query.device.list(space))
    queryClient.ensureQueryData(query.sketch.list(space))
    queryClient.ensureQueryData(query.dashboard.list(space))
    queryClient.ensureQueryData(query.trigger.list(space)) */
    queryClient.ensureQueryData(query.other.property_types)
  }
})
