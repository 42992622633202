import { authFetch, getXOrganizationHeaders } from "@/api/utils"
import { unwrapResponse } from "@/api/utils/NetworkError"
import type { Space, TriggerHistory } from "@/types"

// ! This doesn't have an OpenAPI schema. Sad... 😔

/*
 * =====================
 * Manual API functions
 *
 * These functions are manually written and are not generated from the OpenAPI schema.
 * =====================
 */

export async function getTriggersHistoryList(spaceID: Space["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API_URL}/notifications`, {
    method: "GET",
    headers: getXOrganizationHeaders(spaceID)
  })
  const data = await unwrapResponse<{ notifications: TriggerHistory[] }>(res)
  return data.notifications
}
