import { createFileRoute, redirect } from "@tanstack/react-router"

import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/trash/")({
  staticData: {
    title: "Trash"
  },

  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: () => {
    redirect({
      to: "/",
      throw: true
    })
    /*
    const space = spaceStorage.getSpace()

    queryClient.prefetchQuery(query.space.members(space))

    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/trash", {
        params: {
          header: getXOrganizationHeaders(space)
        }
      })
    )
     */
  }
})
