import { MY_CLOUD_SPACE } from "@bcmi-labs/cloud-sidebar"

import type { Space } from "@/types"

export const MY_SPACE = MY_CLOUD_SPACE as Space

export const SPACE_PAID_PLANS = {
  // This is an employees-only plan
  arduino: "Arduino",
  "create-entry": "Entry",
  "create-maker": "Maker",
  "create-maker-plus": "Maker Plus",
  "cloud-enterprise": "Enterprise",
  "cloud-classroom": "School"
} as const

export const ORGANIZATION_TYPES = [
  { value: "Middle School", label: "Middle School" },
  { value: "High School", label: "High School" },
  { value: "University", label: "University" },
  { value: "Fablab", label: "Fablab" },
  { value: "Other", label: "Other" }
] as const
