import { createFileRoute } from "@tanstack/react-router"

import { $rqViewsApiClient } from "@/api/groups"
import { query } from "@/api/query"
import { getXOrganizationHeaders } from "@/api/utils"
import { routeGuard } from "@/lib/routing/beforeLoad"

type SearchParams = {
  forcesearch?: boolean
}

export const Route = createFileRoute("/_authlayout/_sidebar/devices/")({
  staticData: {
    title: "Devices"
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => ({
    forcesearch: typeof search.forcesearch === "boolean" ? search.forcesearch : undefined
  }),
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: async ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.prefetchQuery(query.space.members(space))
    queryClient.prefetchQuery(query.boards.list)

    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/devices", {
        params: {
          header: getXOrganizationHeaders(space)
        }
      })
    )

    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/things", {
        params: {
          header: getXOrganizationHeaders(space)
        }
      })
    )
  }
})
