import type { LiteralToPrimitive } from "type-fest"
import * as config from "virtual:config"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

import { featureFlags } from "@/store/featureflags/flags"

type DefaultFlagValues = {
  [K in keyof typeof featureFlags]: (typeof featureFlags)[K]["options"][number]["value"]
}

// Reduce function to build the default flags store while keeping types widened
const defaultFlagsStore: DefaultFlagValues = Object.keys(featureFlags).reduce(
  (acc, flag) => {
    const key = flag as keyof typeof featureFlags
    // Assign defaultValue while widening its type
    return {
      ...acc,
      [key]: featureFlags[key].default
    }
  },
  {} as DefaultFlagValues // Cast the accumulator to DefaultFlagValues
)

interface FeatureFlagsStoreState {
  flags: typeof defaultFlagsStore
  setFeatureFlag: (
    flag: keyof typeof featureFlags,
    value: LiteralToPrimitive<(typeof featureFlags)[keyof typeof featureFlags]["options"][number]["value"]> | string
  ) => void
  getDefaultFlags: () => typeof featureFlags
  resetFlags: () => void
}

// Conditional persist middleware for development only.
const conditionalPersist = (config.mode !== "production" ? persist : (fn: unknown) => fn) as typeof persist
/**
 * Modal store.
 */
export const useFeatureFlagsStore = create<FeatureFlagsStoreState>()(
  conditionalPersist(
    set => ({
      flags: defaultFlagsStore,
      setFeatureFlag: (flag, value) => {
        set(state => ({ flags: { ...state.flags, [flag]: typeof value === "string" ? convertValue(value) : value } }))
      },
      getDefaultFlags: () => featureFlags,
      resetFlags: () => set({ flags: defaultFlagsStore })
    }),
    {
      name: "featureflags-store",
      storage: createJSONStorage(() => localStorage),
      version: 0,
      merge: (persistedState, currentState) => {
        if (!persistedState) {
          return currentState
        }

        const newState = { ...currentState, ...persistedState }
        newState.flags = { ...defaultFlagsStore, ...newState.flags }

        // Remove any keys that are not in the default flags.
        Object.keys(newState.flags).forEach(key => {
          if (!(key in featureFlags)) {
            delete newState.flags[key as keyof typeof featureFlags]
          }
        })

        return newState
      }
    }
  )
)

function convertValue(val: string) {
  // If the value is a boolean, convert it to a boolean
  if (val === "true" || val === "false") Boolean(val)
  // If the value is a number, convert it to a number
  if (!Number.isNaN(Number(val))) return Number(val)
  return val
}
