import { createFileRoute, redirect } from "@tanstack/react-router"

import { $rqCustomizationApiClient } from "@/api/customization"
import { query } from "@/api/query"
import { MY_SPACE } from "@/constants/spaces"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/branding/")({
  staticData: {
    title: "Branding"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context, { allowPrivateSpace: false })

    const spaces = await context.queryClient.ensureQueryData(query.space.list)
    const space = spaces.find(sp => sp.id === context.spaceStorage.getSpace()) || MY_SPACE

    // If the space is not an enterprise space, redirect to root
    if (space.space !== "pro") {
      redirect({
        to: "/",
        throw: true
      })
    }
  },

  loader: ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.prefetchQuery(
      $rqCustomizationApiClient.queryOptions("get", "/v1/customizable/ofOrg/{organization_id}", {
        params: { path: { organization_id: space } }
      })
    )
  }
})
