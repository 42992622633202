import { createFileRoute } from "@tanstack/react-router"

import { $rqIotApiClient } from "@/api/iot"
import { routeGuard } from "@/lib/routing/beforeLoad"

export const Route = createFileRoute("/_authlayout/_sidebar/api-keys/")({
  staticData: {
    title: "API Keys"
  },
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loader: ({ context: { queryClient } }) => {
    queryClient.prefetchQuery($rqIotApiClient.queryOptions("get", "/iot/v1/clients"))
  }
})
