import { useAuth } from "@bcmi-labs/art-auth"
import { lazy, Suspense } from "react"

import { GlobalElementsBannerPortal } from "@/components/helpers/GlobalElements"
import useWindowDimensions from "@/lib/hooks/useWindowDimensions"
import { useUserStorageStore } from "@/store/userstorage"

const Welcome = lazy(async () => ({
  default: (await import("./welcome")).default
}))

const WalkthroughChangelog = lazy(async () => ({
  default: (await import("./changelog")).default
}))

export function WalkthroughArea() {
  const { user } = useAuth()
  const isMobile = useWindowDimensions(768)

  const welcomeTour = useUserStorageStore(s => s.tours["welcome-tour"])
  const changelogTour = useUserStorageStore(s => s.tours["changelog-tour"])

  const allViewed = welcomeTour.viewed && changelogTour.viewed

  if (isMobile || user["http://arduino.cc/is_minor"] || allViewed) {
    return null
  }

  return (
    <GlobalElementsBannerPortal>
      {!welcomeTour.viewed ? (
        <Suspense fallback={null}>
          <Welcome />
        </Suspense>
      ) : null}
      {!changelogTour.viewed ? (
        <Suspense fallback={null}>
          <WalkthroughChangelog />
        </Suspense>
      ) : null}
    </GlobalElementsBannerPortal>
  )
}
