import createClient from "openapi-react-query"

import { createApiClient } from "@/api/utils"
import type { paths as customizationPaths } from "@/types/api/customization/schema"

// Vanilla API client
export const $customizationApiClient = createApiClient<customizationPaths>({ baseUrl: import.meta.env.VITE_API_URL })

// React-Query client
export const $rqCustomizationApiClient = createClient($customizationApiClient)
