// Legacy page to flag chromebook app users coming from the Chrome Web Store.
// See: https://github.com/bcmi-labs/iot-cloud.arduino.cc/blob/main/src/views/installPWAChromeOS/InstallPWAChromeOS.tsx

import { createFileRoute } from "@tanstack/react-router"

import { sessionStorageKeys } from "@/constants/storageKeys"

export const Route = createFileRoute("/chrome-app")({
  beforeLoad: () => {
    // Set legacy flag to true
    localStorage.setItem("create.installed-from-store", "true")

    // New, session-bound flag
    sessionStorage.setItem(sessionStorageKeys.CHROME_APP, "true")

    // Reload the whole app to make sure event listeners are re-attached
    // eslint-disable-next-line cloud-website/no-location-href-assign
    window.location.assign("/")
  }
})
