import { useEffect, useRef } from "react"

/**
 *
 * @param value
 * @param cb
 */
export function useOnChange<T>(value: T, cb: (prev: T, next: T) => void) {
  const latestValue = useRef(value)

  useEffect(
    function onChange() {
      if (value !== latestValue.current) {
        latestValue.current = value // Update the latest value
        cb(latestValue.current, value)
      }
    },
    [cb, value]
  )
}
