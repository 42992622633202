import { MY_CLOUD_SPACE } from "@bcmi-labs/cloud-sidebar"

import { unwrapResponse } from "@/api/utils/NetworkError"
import type { Roles } from "@/constants/roles"
import { AuthClientInstance } from "@/lib/services/instances"
import type { Kit, Member, Space } from "@/types"

import { authFetch, authXhr } from "./utils"

// TODO: Migrate to client

export async function getSpaceKits(spaceID: Space["id"]) {
  if (!spaceID) return []
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/kits`)
  const list = await unwrapResponse<Kit[]>(res)
  return list
}

export async function addMember(
  payload: {
    member_email: string
    member_role?: keyof typeof Roles
  },
  spaceID: Space["id"]
) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/members`, {
    method: "POST",
    body: JSON.stringify(payload)
  })
  return unwrapResponse(res)
}

export async function addMemberViaCode(payload: { code: string }) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/self-invitation/members`, {
    method: "POST",
    body: JSON.stringify(payload)
  })
  return unwrapResponse<Response>(res)
}

export async function removeMember(memberID: string, spaceID: Space["id"]) {
  if (!(spaceID && memberID)) return null
  const res = await authFetch(
    `${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/members/${memberID}`,
    {
      method: "DELETE"
    }
  )
  return unwrapResponse(res)
}

export async function leaveSpace(spaceID: Space["id"], userID?: Member["id"] | null) {
  if (!spaceID) return null
  const res = await authFetch(
    `${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/members/${userID}/membership`,
    {
      method: "DELETE"
    }
  )
  return unwrapResponse(res)
}

export async function disbandSpace(spaceID: Space["id"]) {
  if (!spaceID) return null
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}`, {
    method: "DELETE"
  })
  return unwrapResponse(res)
}

export async function getSpacesClean() {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/classroom/v1/organizations`)
  const { organizations } = await unwrapResponse<{ count: number; organizations: Space[] }>(res)
  return organizations
}

/**
 * Get the list of members for the given space. If the space is the user's personal space, return the user's data.
 */
export async function getUsersList(spaceID: Space["id"]) {
  const authUser = await AuthClientInstance.getUser()

  /**
   * TODO: Refactor this to use the new API
   *
   * This is okay for now: once we refactor this to use openapi as well, we'll have to always read the
   * member list via a custom hooks because we likely can't alter the API to ignore
   * the error that's thrown when the request is made without a spaceID.
   */

  if (!authUser) return []
  if (spaceID === MY_CLOUD_SPACE.id)
    return [
      {
        id: authUser["http://arduino.cc/id"],
        username: authUser.name,
        name: authUser.name,
        email: authUser.email,
        avatar: authUser.picture,
        is_minor: authUser["http://arduino.cc/is_minor"]
      } as Member
    ]

  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/members`)
  const { members } = await unwrapResponse<{ count: number; members: Member[] }>(res)

  // Replace the user's data with the auth0 user data
  return members.map(member => {
    if (member.id === authUser["http://arduino.cc/id"]) {
      return {
        id: authUser["http://arduino.cc/id"],
        username: authUser.name,
        name: authUser.name,
        email: authUser.email,
        avatar: authUser.picture,
        is_minor: authUser["http://arduino.cc/is_minor"],
        roles: member.roles,
        last_update: member.last_update
      }
    }
    return member
  })
}

export async function getSpacesInviteCode(spaceID: Space["id"]) {
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/code`)
  return (await unwrapResponse<{ code: string }>(res)).code
}

export async function changeRole(spaceID: Space["id"], userID: string, member_role: keyof typeof Roles) {
  if (!userID) return null
  const res = await authFetch(
    `${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/members/${userID}/role`,
    {
      method: "PATCH",
      body: JSON.stringify({
        member_role
      })
    }
  )
  return unwrapResponse(res)
}

export async function updateSpace(payload: Partial<Space>, spaceID: Space["id"]) {
  if (!(payload.name || payload.type || payload.country) || !spaceID) return null
  const res = await authFetch(`${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}`, {
    method: "PATCH",
    body: JSON.stringify(payload)
  })
  return unwrapResponse<Space>(res)
}

const UploadTypeMap = {
  "Icon Logo": "logo",
  "Horizontal Logo": "second"
} as const
export async function uploadLogo(xhr: XMLHttpRequest, type: keyof typeof UploadTypeMap, logo: Blob, spaceID: string) {
  // Using XHR because Fetch API doesn't support request progression yet. See: https://fetch.spec.whatwg.org/#fetch-api
  const formData = new FormData()
  formData.append("logo", logo, "logo")

  await authXhr(
    xhr,
    {
      method: "POST",
      url: `${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/logo${
        UploadTypeMap[type] !== "logo" ? `?type=${UploadTypeMap[type]}` : ""
      }`
    },
    formData
  )
}

export async function deleteLogo(spaceID: Space["id"] | null, type: keyof typeof UploadTypeMap) {
  const res = await authFetch(
    `${import.meta.env.VITE_API2_URL}/classroom/v1/organizations/${spaceID}/logo${
      UploadTypeMap[type] !== "logo" ? `?type=${type}` : ""
    }`,
    {
      method: "DELETE"
    }
  )
  await unwrapResponse(res)
}
