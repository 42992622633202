import { useAuth } from "@bcmi-labs/art-auth"
import { Loader } from "@bcmi-labs/art-ui/components"
import type { ComponentProps } from "react"

import styles from "./index.module.scss"

/**
 * A loader shaped like the Arduino logo.
 *
 * If no `variant` is provided, the loader will be rendered with these default styles:
 * - While the user isn't authenticated and the main store is loading, the loader will be rendered with the `primary` variant.
 * - Otherwise, the loader will be rendered with the `secondary` variant.
 */
export function PageLoader(props: ComponentProps<typeof Loader>) {
  const { variant, ...rest } = props
  const { isAuthenticated } = useAuth()

  return (
    <div className={styles.PageLoader} style={{ height: "100%" }}>
      <Loader variant={variant || (!isAuthenticated ? "primary" : "secondary")} {...rest} />
    </div>
  )
}
