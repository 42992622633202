import { createFileRoute, redirect } from "@tanstack/react-router"

import { routeGuard } from "@/lib/routing/beforeLoad"

type SearchParams = {
  /**
   * The folder id for the current path
   */
  folder?: string
  search?: string
  forcesearch?: boolean
}

export const Route = createFileRoute("/_authlayout/_sidebar/allitems/")({
  staticData: {
    title: "All items"
  },
  validateSearch: (params: Record<string, unknown>): SearchParams => ({
    folder: params.folder as string,
    search: typeof params.search === "string" ? params.search : undefined,
    forcesearch: typeof params.forcesearch === "boolean" ? params.forcesearch : undefined
  }),
  beforeLoad: async ({ context }) => {
    await routeGuard(context)
  },
  loaderDeps: ({ search: { folder } }) => ({ folder }),
  loader: async () => {
    redirect({
      to: "/",
      throw: true
    })

    /* 
    const space = spaceStorage.getSpace()

    await queryClient.prefetchQuery(query.space.members(space))

    // Preload the view
    queryClient.prefetchQuery(
      $rqViewsApiClient.queryOptions("get", "/groups/v1/all", {
        params: {
          header: getXOrganizationHeaders(space),
          query: { parent_id: deps.folder }
        }
      })
    )

    if (deps.folder) {
      queryClient
        .prefetchQuery(
          $rqFoldersApiClient.queryOptions("get", "/groups/v1/folders/{folder_id}", {
            params: {
              header: getXOrganizationHeaders(space),
              path: { folder_id: deps.folder }
            }
          })
        )
        // TODO: This needs to be handled in the component itself...
        .catch(e => {
          // Possibly an invalid folder id.
          if (e instanceof NetworkError && (e.body.status === 500 || e.body.status === 404)) {
            // Redirect
          }
          // Otherwise, re-throw the error.
          throw e
        })

      queryClient.prefetchQuery(
        $rqViewsApiClient.queryOptions("get", "/groups/v1/all", {
          params: {
            header: getXOrganizationHeaders(space),
            query: { parent_id: deps.folder }
          }
        })
      )
    }
     */
  }
})
