import { GenericError, useAuth } from "@bcmi-labs/art-auth"
import { Button } from "@bcmi-labs/art-ui/components"
import { Typography } from "@bcmi-labs/art-ui/primitives"
import { useQueryErrorResetBoundary } from "@tanstack/react-query"
import type { ErrorComponentProps } from "@tanstack/react-router"
import { useRouter } from "@tanstack/react-router"
import * as config from "virtual:config"

import { NetworkError, parseError } from "@/api/utils/NetworkError"
import { ExternalLink } from "@/components/ExternalLink"

import styles from "./index.module.scss"

// TODO: Find out why this does sometimes get stuck in after navigating...
export function ErrorLayout({ error }: { error?: ErrorComponentProps }) {
  let stack: string | undefined
  let url: string | undefined
  let errorType: "generic" | "auth" = "generic"
  let errorMessage = "An unknown error occurred."

  // Check if the error is an instance of NetworkError

  if (error?.error instanceof NetworkError) {
    const parsed = parseError(error.error)
    errorMessage = `${parsed.body.name}: ${parsed.body.detail}`
    url = parsed.body.url
    stack = error.error.stack
  } else if (error?.error instanceof GenericError) {
    errorMessage = `${error.error.error}: ${error.error.message}`
    stack = error.error.stack
    errorType = "auth"
  } else if (error?.error instanceof Error) {
    errorMessage = `${error.error.name}: ${error.error.message}`
    stack = error.error.stack
  }

  const { reset } = useQueryErrorResetBoundary()
  const router = useRouter()
  const auth = useAuth()

  return (
    <div className={styles.errorBoundary}>
      <img src={`/images/illustrations/${errorType === "generic" ? "generic-error.svg" : "auth-error.svg"}`} />
      <div className={styles["errorBoundary--text"]}>
        <Typography size="large">
          {errorType === "generic" ? "It's just one of those days." : "We're having trouble logging you in."}
        </Typography>
        <Typography
          style={{
            fontStyle: errorType === "generic" ? "normal" : "italic"
          }}>
          {errorType === "generic" ? "We're sorry, but something went wrong." : errorMessage}
        </Typography>
      </div>

      <div className={styles["errorBoundary--cta"]}>
        <Typography size="xx-small">
          Have you tried{" "}
          <span
            className="link"
            onClick={() => {
              reset()
              router.invalidate()
            }}>
            reloading the page
          </span>
          ? If this is taking too long you can write us on the{" "}
          <ExternalLink className="link" href="https://forum.arduino.cc/">
            Forum
          </ExternalLink>{" "}
          or check the{" "}
          <ExternalLink className="link" href="https://status.arduino.cc/">
            Status page
          </ExternalLink>
          . <br />
          If the issue persists, you can
          <ExternalLink className="link" href="https://www.arduino.cc/en/contact-us/">
            {" "}
            contact us
          </ExternalLink>
          .
        </Typography>
      </div>
      {errorType === "auth" ? (
        <Button
          size="small"
          onClick={() => {
            auth.client.logout({ logoutParams: { returnTo: window.location.origin } })
          }}>
          Log out
        </Button>
      ) : null}
      {config.mode !== "production" ? (
        <pre className={styles["errorBoundary--stack"]}>
          <b>{errorMessage}</b>
          {url ? (
            <>
              <br />
              <i>
                {`Endpoint: ${url}`}
                <br />
              </i>
            </>
          ) : null}
          {stack ? (
            <>
              <br />
              <b>
                <i>Stack trace:</i>
              </b>
              <br />
              {stack}
            </>
          ) : null}
        </pre>
      ) : null}
    </div>
  )
}
